import { combineReducers } from 'redux'
// reducer files
// import users from './admin-users'
import authentication from './authentication'

const appReducer = combineReducers({
  // users,
  authentication,
})

const rootReducer = (state, action) => {
  return appReducer(state, action)
}

export default rootReducer
