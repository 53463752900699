import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { BrowserRouter, Switch, Redirect } from "react-router-dom";
import Sidebar from "../components/Sidebar";
import PrivateRoute from '../config/private-route'
import Routes from '../config/routes'
import Dashboard from '../views/Dashboard'
// import UserControl from '../views/UserControl'
import UserCourseExtension from '../views/UserCourseExtension';
import CourseMasterSheet from '../views/CourseMasterSheet';
import TransactionDetails from '../views/TransactionDetails';
import UserUnlockModule from '../views/UserUnlockModule';
import MicroCoursePage from '../views/MicroCourses/MicroCoursePage';
import RouteMapGenerator from '../views/RouteMapGenerator';
import DataExports from '../views/DataExports/DataExports';
import PopQuizPage from '../views/PopQuiz/PopQuizPage';

// import AdminNavbar from "components/Navbars/AdminNavbar";
// import Footer from "components/Footer/Footer";
// import sidebarImage from "assets/img/sidebar-3.jpg";

function Admin() {
    // const [image, setImage] = React.useState(sidebarImage);
    const [color, setColor] = React.useState("blue");
    // const [hasImage, setHasImage] = React.useState(true);

    const token = useSelector(store => store.authentication.token)
    const [loginRedirect, setLoginRedirect] = useState(false)

    const mainPanel = React.useRef(null);

    useEffect(() => {
        if (!token)
            setLoginRedirect(true)
    }, [])

    return (
        <div className="wrapper">

            {/* LOGIN REDIRECT */}
            {loginRedirect && <Redirect to={Routes.Login} />}

            {/* otiginal copy */}
            {/* <Sidebar color={color} image={hasImage ? image : ""} routes={routes} /> */}
            <Sidebar color={color} />

            {/* <BrowserRouter></BrowserRouter> */}
            <div className="main-panel" ref={mainPanel}>
                {/* <AdminNavbar /> */}
                <div className="content">
                    <Switch>
                        <PrivateRoute exact path={Routes.Dashboard} component={Dashboard} />
                        <PrivateRoute exact path={Routes.UserCourseExtension} component={UserCourseExtension} />
                        <PrivateRoute exact path={Routes.CourseMasterSheet} component={CourseMasterSheet} />
                        <PrivateRoute exact path={Routes.TransactionDetails} component={TransactionDetails} />
                        <PrivateRoute exact path={Routes.UserUnlockModule} component={UserUnlockModule} />
                        <PrivateRoute exact path={Routes.MicroCourses} component={MicroCoursePage} />
                        <PrivateRoute exact path={Routes.RouteMapGenerator} component={RouteMapGenerator} />
                        <PrivateRoute exact path={Routes.DataExports} component={DataExports} />
                        <PrivateRoute exact path={Routes.PopQuiz} component={PopQuizPage} />
                        {/* <PrivateRoute exact path={Routes.UserControl} component={UserControl} /> */}
                        {/* <PrivateRoute exact path='/link' component={PaymentLink}></PrivateRoute> */}

                    </Switch>
                </div>
            </div>
        </div>
    );
}

export default Admin;
