import { useLocation, NavLink } from "react-router-dom";
import Icon from "../utils/icon";
import { Nav } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { logout } from "../redux/actions/authentication";
import Routes from "../config/routes";

function Sidebar({ color, image }) {
  const location = useLocation();
  const activeRoute = (routeName) => {
    return location.pathname.indexOf(routeName) > -1 ? "active" : "";
  };

  const user_role = useSelector((store) => store.authentication?.role);
  const dispatch = useDispatch();

  return (
    <div className="sidebar" data-image={image} data-color={color}>
      <div
        className="sidebar-background"
        style={{
          backgroundImage: "url(" + image + ")",
        }}
      />
      <div className="sidebar-wrapper">
        <div className="logo d-flex align-items-center justify-content-start">
          <Icon icon="ICON_CAREER_NINJA" />
        </div>
        <Nav>
          <li className={activeRoute(Routes.Dashboard)}>
            <NavLink
              to={Routes.Dashboard}
              className="nav-link"
              activeclassname="active"
            >
              {/* <i className={prop.icon} /> */}
              <div>
                <i className="fa fa-user-alt"></i>
                <p>Dashboard</p>
              </div>
            </NavLink>
          </li>

          {user_role === "ADMIN" && (
            <li className={activeRoute(Routes.CourseMasterSheet)}>
              <NavLink
                to={Routes.CourseMasterSheet}
                className="nav-link"
                activeclassname="active"
              >
                <i className="fa fa-newspaper" />
                <p>Course Mastersheet</p>
              </NavLink>
            </li>
          )}

          {user_role === "ADMIN" && (
            <li className={activeRoute(Routes.UserUnlockModule)}>
              <NavLink
                to={Routes.UserUnlockModule}
                className="nav-link"
                activeclassname="active"
              >
                <i className="fa fa-unlock" />
                <p>Unlock Module</p>
              </NavLink>
            </li>
          )}

          {user_role === "ADMIN" && (
            <li className={activeRoute(Routes.MicroCourses)}>
              <NavLink
                to={Routes.MicroCourses}
                className="nav-link"
                activeclassname="active"
              >
                <i className="fa fa-spa" />
                <p>Micro-Courses</p>
              </NavLink>
            </li>
          )}
          {user_role === "ADMIN" && (
            <li className={activeRoute(Routes.PopQuiz)}>
              <NavLink
                to={Routes.PopQuiz}
                className="nav-link"
                activeclassname="active"
              >
                <i className="fa fa-tasks" />
                <p>Pop Quiz</p>
              </NavLink>
            </li>
          )}

          {user_role === "ADMIN" && (
            <li className={activeRoute(Routes.RouteMapGenerator)}>
              <NavLink
                to={Routes.RouteMapGenerator}
                className="nav-link"
                activeclassname="active"
              >
                <i class="fa fa-compass" aria-hidden="true"></i>
                <p>Route Map Viewer</p>
              </NavLink>
            </li>
          )}

          {user_role === "ADMIN" && (
            <li className={activeRoute(Routes.UserCourseExtension)}>
              <NavLink
                to={Routes.UserCourseExtension}
                className="nav-link"
                activeclassname="active"
              >
                <i className="fa fa-plus" />
                <p>Course Extension</p>
              </NavLink>
            </li>
          )}

          {user_role === "ADMIN" && (
            <li className={activeRoute(Routes.TransactionDetails)}>
              <NavLink
                to={Routes.TransactionDetails}
                className="nav-link"
                activeclassname="active"
              >
                <i className="fa fa-table" />
                <p>Transaction Details</p>
              </NavLink>
            </li>
          )}

          {user_role === "ADMIN" && (
            <li className={activeRoute(Routes.DataExports)}>
              <NavLink
                to={Routes.DataExports}
                className="nav-link"
                activeclassname="active"
              >
                <i class="fa fa-file-excel" aria-hidden="true"></i>
                <p>Data Exports</p>
              </NavLink>
            </li>
          )}

          <li className={activeRoute(Routes.Login)}>
            <div
              onClick={() => dispatch(logout())}
              className="nav-link"
              activeclassname="active"
            >
              <i className="fa fa-sign-out-alt" />
              <p>Log Out</p>
            </div>
          </li>

          {/* <Link to='/users' onClick={props.toggleOpen} style={styles.sidebarLink}>
                        Users
                    </Link> */}

          {/* {props.user_role === 'ADMIN' && (
                            <Link to='/admin-user' onClick={props.toggleOpen} style={styles.sidebarLink}> Admin Users </Link> 
                            )} */}

          {/* {props.user_role === 'ADMIN' && (
                            <Link to='/whatsapp' onClick={props.toggleOpen} style={styles.sidebarLink}>Whatsapp</Link>
                        )} */}

          {/* {props.user_role === 'ADMIN' && (
                            <Link to='/link' onClick={props.toggleOpen} style={styles.sidebarLink}>Payment Links</Link>
                    )} */}
        </Nav>
      </div>
    </div>
  );
}

export default Sidebar;
