import React from 'react'
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import * as dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'

dayjs.extend(relativeTime)

export default function PaymentHistory({ paymentDetails, userDetails }) {

    if (paymentDetails.length > 0) {
        paymentDetails = paymentDetails.map(detail => ({ ...detail, leadName: `${userDetails.firstname} ${userDetails.lastname}` }))
    }

    if (paymentDetails.length > 0) {
        return (
            <div className="ag-theme-alpine grid-div mt-5">
                <AgGridReact
                    rowData={paymentDetails}
                    defaultColDef={{
                        flex: 1,
                        resizable: true,
                        floatingFilter: true,
                        minWidth: 140
                    }}
                    pagination
                >
                    <AgGridColumn field="updated_at" sortable headerName="Date"
                        valueGetter={(params) => dayjs(new Date(params.data.updated_at)).format('DD MMM YYYY, hh:mm a')} minWidth={200} />
                    <AgGridColumn field="leadName" headerName="Lead's Name" sortable filter minWidth={200} />
                    <AgGridColumn field="leadOwner" headerName="Lead Owner" sortable filter minWidth={200} />
                    <AgGridColumn field="email" sortable filter minWidth={200} />
                    <AgGridColumn field="type" sortable filter />
                    <AgGridColumn field="scheme" headerName="Plan" sortable filter minWidth={200} />
                    <AgGridColumn field="customPlan" headerName="Custom Plan" sortable filter minWidth={200} />
                    <AgGridColumn field="paid_amount" headerName="Booked Amount" minWidth={170} sortable filter="agNumberColumnFilter" />
                    <AgGridColumn field="refunded_amount" headerName="Realised Amount" minWidth={170} sortable filter="agNumberColumnFilter" valueGetter={(params) => params.data.refunded_amount ? 0 : params.data.refunded_amount} />
                    <AgGridColumn field="days_extended" sortable valueGetter={(params) => params.data.days_extended > 1000 ? 'Lifetime' : params.data.days_extended} />
                    <AgGridColumn field="cv" headerName="CV Access"
                        valueGetter={(params) => params.data.cv === true ? 'Unlocked' : 'Locked'}
                    />
                    <AgGridColumn field="certificate" headerName="Certificate Access"
                        valueGetter={(params) => params.data.certificate === true ? 'Unlocked' : 'Locked'} />
                    <AgGridColumn field="note" minWidth={300} />
                    <AgGridColumn field="payment_method" filter />
                    <AgGridColumn field="currency" sortable filter />
                </AgGridReact>
            </div>
        )
    } else {
        return (
            <h5>No payments record found!</h5>
        )
    }
}
