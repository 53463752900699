import {
  Card,
  ListGroup,
  ListGroupItem,
  Button,
  Modal,
  Container,
  Breadcrumb
} from "react-bootstrap";
import allContents from "../../contents";
import exportFromJSON from "export-from-json";
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import { useState } from "react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "./course-mastersheet.scss";

const CourseMasterSheet = () => {
  const [gridApi, setGridApi] = useState(null);
  const [selectedRow, setSelectedRow] = useState({});
  const [showModal, setShowModal] = useState(false);

  const hashValueGetter = (params) => {
    return params.node.rowIndex;
  };

  const onGridReady = (params) => {
    setGridApi(params.api);
  };

  const onSelectionChanged = () => {
    const selectedRows = gridApi.getSelectedRows();
    setSelectedRow(selectedRows[0]);
    handleShowModal();
  };

  const exportToExcel = () => {
    const fileName = "download";
    const exportType = "xls";
    exportFromJSON({ data: allContents, fileName, exportType });
  };

  const handleCloseModal = () => setShowModal(false);
  const handleShowModal = () => setShowModal(true);

  const { expression, level, link, module, phase, priority, title, type, _id } =
    selectedRow;

  return (
    <Container fluid>

      <Breadcrumb>
        <Breadcrumb.Item active>Course MasterSheet</Breadcrumb.Item>
      </Breadcrumb>

      <div className="d-flex justify-content-end mt-2">
        <Button variant="primary" style={{ padding: '7px 26px' }} onClick={exportToExcel}>
          Export
        </Button>
      </div>
      <br />
      <div className="ag-theme-alpine grid-div">
        <AgGridReact
          rowData={allContents}
          defaultColDef={{
            flex: 1,
            minWidth: 150,
            resizable: true,
            floatingFilter: true,
          }}
          rowSelection={"single"}
          onGridReady={onGridReady}
          onSelectionChanged={onSelectionChanged}
          pagination
        >
          <AgGridColumn headerName="#" valueGetter={hashValueGetter} />
          <AgGridColumn field="_id" sortable filter />
          <AgGridColumn field="expression" sortable filter />
          <AgGridColumn
            field="priority"
            valueGetter={(params) => "Priority_" + params.data.priority}
            sortable
            filter
          />
          <AgGridColumn field="phase" sortable filter />
          <AgGridColumn
            field="module"
            aggFunc
            sortable
            filter
            valueGetter={(params) => "Module_" + params.data.module}
          />
          <AgGridColumn
            field="level"
            sortable
            filter
            valueGetter={(params) => "Level_" + params.data.level}
          />
          <AgGridColumn field="title" sortable filter />
          <AgGridColumn field="link" sortable filter />
          <AgGridColumn field="type" sortable filter />
        </AgGridReact>
      </div>

      <Modal show={showModal} onHide={handleCloseModal} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Content Detail</Modal.Title>
        </Modal.Header>
        <Modal.Body className="pt-2">
          <Card>
            <ListGroup>
              <ListGroupItem className="py-0">Id: {_id}</ListGroupItem>
              <ListGroupItem className="py-0">Phase: {phase}</ListGroupItem>
              <ListGroupItem className="py-0">
                Priority: Priority_{priority}
              </ListGroupItem>
              <ListGroupItem className="py-0">
                Module: Module_{module}
              </ListGroupItem>
              <ListGroupItem className="py-0">
                Level: Level_{level}
              </ListGroupItem>
              <ListGroupItem className="py-0">Title: {title}</ListGroupItem>
              <ListGroupItem className="py-0">Link: {link}</ListGroupItem>
              <ListGroupItem className="py-0">Type: {type}</ListGroupItem>
              <ListGroupItem className="py-0">
                Expression: {expression}
              </ListGroupItem>
            </ListGroup>
          </Card>
        </Modal.Body>
      </Modal>
    </Container>
  );
};

export default CourseMasterSheet;
