import { useState } from "react"
import { useDispatch } from "react-redux"
import { Container, Breadcrumb, Button, Toast, Alert } from "react-bootstrap"
import { getUserDetails } from '../../redux/actions/admin-users'
import exportFromJSON from 'export-from-json'
import "./data-exports.scss"
import AccessKeyModal from "../../components/AccessKeyModal"
import { useSelector } from "react-redux"

export default function DataExports() {

    const authentication = useSelector(store => store?.authentication)
    const dispatch = useDispatch()
    const [excelDownloadLoader, setExcelDownloadLoader] = useState(false)
    const [excelDownloadError, setExcelDownloadError] = useState(false)

    function downloadUserDetailsHandler() {
        setExcelDownloadLoader(true)
        dispatch(getUserDetails(success => {
            const timestamp = new Date().toISOString()
            const fileName = `user_details-${timestamp}`;
            const exportType = "xls";
            exportFromJSON({ data: success.user_details, fileName, exportType });
            setExcelDownloadLoader(false)
        }, (err) => {
            setExcelDownloadLoader(false)
            setExcelDownloadError(true)
            console.log(err)
        }))
    }

    return (
        <>
            <AccessKeyModal showModal={!authentication?.accessKeyEntered} />
            <Container fluid>
                <Breadcrumb>
                    <Breadcrumb.Item active>File Exports</Breadcrumb.Item>
                </Breadcrumb>

                <Alert variant='info' className='d-flex user_details__btn'>
                    <Button variant='success' onClick={downloadUserDetailsHandler} disabled={excelDownloadLoader}>
                        <i className="fa fa-users fa-2x" aria-hidden="true" ></i>
                        <span>Export User Details</span>
                        {excelDownloadLoader && <i class="fa fa-circle-notch fa-spin fa-2x fa-fw"></i>}
                    </Button>
                </Alert>
                {<Toast onClose={() => setExcelDownloadError(false)}
                    show={excelDownloadError}
                    style={{
                        position: "fixed",
                        top: "60px",
                        right: "30px",
                        zIndex: "999999999",
                    }}
                    delay={3000}
                    autohide
                    bg="danger">
                    <Toast.Body style={{ color: 'white' }}>something went wrong. contact dev</Toast.Body>
                </Toast>}
            </Container>
        </>
    )
}