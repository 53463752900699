import React, { useState } from "react";
import { useDispatch } from "react-redux";
import {
  Button,
  Form,
  InputGroup,
  Container,
  Col,
  Row,
  Alert,
  Modal,
  Spinner,
} from "react-bootstrap";
import { createPopQuiz } from "../../redux/actions/popQuiz";
import * as XLSX from "xlsx";
import ReactJson from "react-json-view";
import "./PopQuizPage.scss";

const PopQuizPage = () => {
  const [uploadPreviewed, setUploadPreviewed] = useState(false);
  const [popQuizData, setPopQuizData] = useState();
  const [popQuizUploadResponse, setPopQuizUploadResponse] = useState("");
  const [popQuizUploadResponseError, setPopQuizUploadResponseError] =
    useState("");
  const [workBookContainsErrors, setWorkBookContainsErrors] = useState(false);
  const [showSubmitLoader, setShowSubmitLoader] = useState();
  const dispatch = useDispatch();
  const [sheetLink, setSheetLink] = useState("");

  const isWorkBookValid = (workBook) => {
    if (workBook) {
      if (
        workBook["A1"].v === "id" &&
        workBook["B1"].v === "question" &&
        workBook["C1"].v === "correct_answer" &&
        workBook["D1"].v === "choice_0" &&
        workBook["E1"].v === "choice_1" &&
        workBook["F1"].v === "choice_2" &&
        workBook["G1"].v === "choice_3" &&
        workBook["H1"].v === "quiz_id" &&
        workBook["I1"].v === "hint"
      )
        return true;
    }

    return false;
  };

  const readSpreadSheet = () => {
    if(workBookContainsErrors){
      setWorkBookContainsErrors(false);
    }
    const reader = new FileReader();
    reader.onload = (evt) => {
      const binaryString = evt.target.result;
      const workBook = XLSX.read(binaryString, { type: "binary" });

      const popQuizSheet = workBook.Sheets[workBook.SheetNames[0]];

      if (isWorkBookValid(popQuizSheet)) {
        setWorkBookContainsErrors(false);

        const popQuizJson = XLSX.utils.sheet_to_json(popQuizSheet);
        setPopQuizData(popQuizJson.filter((item) => item.length !== 0));
      } else {
        setWorkBookContainsErrors(true);

        setUploadPreviewed(false);
        return;
      }
    };
    const urlArr = sheetLink.split("/");
    const sheetKey = urlArr[urlArr.length - 2];
    const sheetURL =
      "https://docs.google.com/spreadsheet/pub?key=" +
      sheetKey +
      "&output=xlsx";
    fetch(sheetURL)
      .then((response) => response.blob())
      .then((blob) => {
        reader.readAsBinaryString(blob);
        setSheetLink("");
        setUploadPreviewed(true);
      });
  };

  const handleUploadCancel = () => {
    setPopQuizData("");
    setUploadPreviewed(false);
  };

  const handleSubmit = (e, overwrite) => {
    setShowSubmitLoader(true);
    dispatch(
      createPopQuiz(
        {
          popQuiz: popQuizData,
          overWriteExisting: overwrite,
        },
        (res) => {
          setPopQuizUploadResponse("MCQ Pop Quiz Created");
          setShowSubmitLoader(false);
        },
        (err) => {
          setPopQuizUploadResponseError(err.errors);
          setShowSubmitLoader(false);
        }
      )
    );
    setUploadPreviewed(false);
    setPopQuizUploadResponse("");
    setPopQuizUploadResponseError("");
  };

  const handleCloseUploadResponse = () => {
    setPopQuizData("");
    setUploadPreviewed(false);
    setPopQuizUploadResponseError("");
    setPopQuizUploadResponse("");
  };

  return (
    <div>
      <h1>
        <i className="fa fa-tasks"></i> MCQ PopQuiz Creator
      </h1>
      <hr />

      <h3>Replace Subjective Quiz With MCQ PopQuiz</h3>
      <InputGroup size="sm" className="mb-3">
        <InputGroup.Text id="inputGroup-sizing-sm">
          Google Sheets Link &nbsp; <i className="fa fa-link"></i>
        </InputGroup.Text>
        <Form.Control
          aria-label="Small"
          aria-describedby="inputGroup-sizing-sm"
          onChange={(e) => setSheetLink(e.target.value)}
          value={sheetLink}
        />
        <Button
          disabled={sheetLink === "" || uploadPreviewed}
          variant="success"
          onClick={readSpreadSheet}
        >
          Preview Json
        </Button>
      </InputGroup>
      {workBookContainsErrors && (
        <Alert variant="danger">
          The Google sheets link provided contains errors.
          <Alert.Link
            href="https://docs.google.com/spreadsheets/d/1AhX55vhEoZDXh6N42USW_g5IRlht7ToEK6rhA_Ac120/edit"
            target="_blank"
          >
            &nbsp;View sample google sheets
          </Alert.Link>
        </Alert>
      )}
      <Container className="mb-2">
        <Row lg={6} className="mb-3 justify-content-end">
          {uploadPreviewed && !workBookContainsErrors && (
            <Button
              disabled={showSubmitLoader}
              size="sm"
              variant="danger"
              onClick={handleUploadCancel}
            >
              Cancel
            </Button>
          )}
          {uploadPreviewed && !workBookContainsErrors && (
            <Button
               disabled={showSubmitLoader}
              variant="outline-success"
              size="sm"
              className="mx-3"
              onClick={(e) => handleSubmit(e, false)}
            >
              {!showSubmitLoader ? (
                <i className="fa fa-paper-plane"> Confirm & Submit</i>
              ) : (
                <Spinner
                  as="span"
                  animation="grow"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                >
                  Submitting...
                </Spinner>
              )}
            </Button>
          )}
        </Row>
        <Row>
          <Col lg={12}>
            <ReactJson
              iconStyle="square"
              theme="google"
              indentWidth={3}
              src={{
                pop_quiz_questions: popQuizData,
              }}
            />
          </Col>
        </Row>
      </Container>

      <Modal
        show={popQuizUploadResponseError !== ""}
        onHide={handleCloseUploadResponse}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header>
          <Modal.Title style={{ color: "red" }}>
            MCQ Pop Quiz Already Exists
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>{popQuizUploadResponseError}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            size="sm"
            onClick={handleCloseUploadResponse}
          >
            Close
          </Button>
          <Button
            type="submit"
            onClick={(e) => handleSubmit(e, true)}
            size="sm"
            variant="danger"
          >
            I understand, Overwrite
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={popQuizUploadResponse !== ""}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header>
          <Modal.Title style={{ color: "green" }}>
            {popQuizUploadResponse}
          </Modal.Title>
        </Modal.Header>
        <Modal.Footer>
          <Button
            variant="secondary"
            size="sm"
            onClick={handleCloseUploadResponse}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default PopQuizPage;