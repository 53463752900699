import { axiosAuth } from "../../config/axios-instances";
import { ADMIN_API, PAYMENT_API } from "../../config/config";
import { Api_url } from "../../config/api_url";

export function verifyUserCourse(data, successCallback, errorCallback) {
  return async function (dispatch) {
    try {
      const response = await axiosAuth.post(
        ADMIN_API + Api_url.VerifyUserCourse,
        data
      );
      successCallback &&
        successCallback({
          ...response.data.data[0],
          user_found: response.data.data.length > 0,
        });
    } catch (err) {
      console.log(err);
      errorCallback && errorCallback(err.response.data);
    }
  };
}

export function recordExtension(data, successCallback, errorCallback) {
  return async function (dispatch) {
    try {
      const response = await axiosAuth.post(
        ADMIN_API + Api_url.RecordExtension,
        data
      );
      successCallback && successCallback(response.data);
    } catch (err) {
      console.log(err);
      errorCallback && errorCallback(err.response.data);
    }
  };
}

export function getPaymentRecord(body, successCallback, errorCallback) {
  return async function (dispatch) {
    try {
      const response = await axiosAuth.get(
        PAYMENT_API + Api_url.GetPaymentRecord,
        { params: body }
      );
      successCallback && successCallback(response.data.data);
      return response.data.data;
    } catch (err) {
      console.log(err);
      errorCallback && errorCallback(err.response.data);
    }
  };
}

export function deleteUser(body, successCallback, errorCallback) {
  return async function (dispatch) {
    try {
      const response = await axiosAuth.post(
        ADMIN_API + Api_url.DeleteUser,
        body
      );
      successCallback && successCallback(response.data.data);
      return response.data.data;
    } catch (err) {
      console.log(err);
      errorCallback && errorCallback(err.response.data);
    }
  };
}

export function disableUserAccess(body, successCallback, errorCallback) {
  return async function (dispatch) {
    try {
      const response = await axiosAuth.post(ADMIN_API + Api_url.LockUser, body);
      successCallback && successCallback(response.data.data);
      return response.data.data;
    } catch (err) {
      console.log(err);
      errorCallback && errorCallback(err.response.data);
    }
  };
}

export function getAllTransactions(successCallback, errorCallback) {
  return async function (dispatch) {
    try {
      const response = await axiosAuth.get(
        ADMIN_API + Api_url.GetAllTransactions
      );
      successCallback && successCallback(response.data.data);
      return response.data.data;
    } catch (err) {
      console.log(err);
      errorCallback && errorCallback(err.response.data);
    }
  };
}

export function getUserPathwayDetails(body, successCallback, errorCallback) {
  return async function (dispatch) {
    try {
      const response = await axiosAuth.get(ADMIN_API + Api_url.GetUserPathwayDetails, { params: body })
      successCallback && successCallback(response.data.data);
      return response.data.data;
    } catch (err) {
      console.log(err)
      errorCallback && errorCallback(err.response.data)
    }

  }
}