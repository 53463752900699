import { Row, Table, Badge } from 'react-bootstrap'
import { useState, useEffect } from 'react'
import * as dayjs from 'dayjs'
import './user-plan-details.scss'

function UserPlanDetails({ userDetails, lastBooking }) {

    const [emiAmount, setEmiAmount] = useState(0)

    useEffect(() => {
        if (userDetails && userDetails.user_found && lastBooking && userDetails.subscription.emi) {
            const amt = ((userDetails.subscription.price - lastBooking.paid_amount) / userDetails.subscription.emi).toFixed(2)
            setEmiAmount(amt)
        }
        else setEmiAmount('NA')
    }, [lastBooking])


    if (userDetails === undefined)
        return null
    else if (userDetails.user_found) {

        return (
            <>
                <Table className="mt-5" size="sm" borderless={true}>
                    <tbody>
                        <tr>
                            <th><p className="form-title">Current Status:</p></th>
                            <td>
                                <p className="form-value">{userDetails.is_inactive ? 'Expired' : 'Active'}</p>
                            </td>
                        </tr>

                        <tr>
                            <th><p className="form-title">Name:</p></th>
                            <td>
                                <p className="form-value">{userDetails.firstname} {userDetails.lastname}</p>
                            </td>

                            <th><p className="form-title">Plan Amount:</p></th>
                            <td>
                                <p className="form-value">{userDetails.subscription.price ?? 'NA'}</p>
                            </td>
                        </tr>

                        <tr>
                            <th><p className="form-title">Phone Number:</p></th>
                            <td>
                                <p className="form-value">+{userDetails.mobile}</p>
                            </td>

                            <th><p className="form-title">Realized Amount:</p></th>
                            <td>
                                <p className="form-value">{userDetails?.subscription?.paid_amount ?? 'NA'}</p>
                            </td>
                        </tr>

                        <tr>
                            <th><p className="form-title">Current Plan:</p></th>
                            <td>
                                <p className="form-value">{userDetails.subscription.active}</p>
                            </td>

                            <th><p className="form-title">Balance Amount:</p></th>
                            <td>
                                <p className="form-value">{userDetails.subscription.price ? userDetails.subscription.price - userDetails.subscription.paid_amount : 'NA'}</p>
                            </td>
                        </tr>

                        <tr>
                            <th><p className="form-title">Time of expiry:</p></th>
                            <td>
                                <p className="form-value">{userDetails.expiry_time}</p>
                            </td>

                            <th><p className="form-title">CV Status:</p></th>
                            <td>
                                <p className="form-value">{userDetails.cv ? 'Unlocked' : 'Locked'}</p>
                            </td>
                        </tr>

                        <tr>
                            <th><p className="form-title">EMI Opted:</p></th>
                            <td>
                                <p className="form-value">{userDetails?.subscription?.emi ? 'Active' : 'NA'}</p>
                            </td>

                            <th><p className="form-title">Certificate Status:</p></th>
                            <td>
                                <p className="form-value">{userDetails.certificate ? 'Unlocked' : 'Locked'}</p>
                            </td>
                        </tr>

                        <tr>
                            <th><p className="form-title">EMI Period:</p></th>
                            <td>
                                <p className="form-value">{userDetails?.subscription?.emi ?? 'NA'}</p>
                            </td>

                            <th><p className="form-title">EMI Amount:</p></th>
                            <td>
                                <p className="form-value">{emiAmount}</p>
                            </td>
                        </tr>

                        <tr>
                            <th><p className="form-title">Last Payment Date:</p></th>
                            <td>
                                <p className="form-value">{dayjs(userDetails?.subscription?.payment_date).format('DD MMM YYYY, h:m a') ?? 'NA'}</p>
                            </td>
                        </tr>

                        <tr>
                            <th>
                                <p className='form-title'>Goal:</p>
                            </th>
                            <td>
                                {userDetails?.onboarding?.goal?.length > 0 ? userDetails?.onboarding.goal.map(item =>
                                    <Badge bg="primary" className='badge-item'>{item}</Badge>
                                ) : <p className='form-value'>N/A</p>}
                            </td>
                            <th>
                                <p className='form-title'>Stage:</p>
                            </th>
                            <td>
                                {userDetails?.onboarding?.stage?.length > 0 ? userDetails?.onboarding.stage.map(item =>
                                    <Badge bg="primary" className='badge-item'>{item}</Badge>
                                ) : <p className='form-value'>N/A</p>}
                            </td>
                        </tr>

                        <tr>
                            <th>
                                <p className='form-title'>Major:</p>
                            </th>
                            <td>
                                {userDetails?.onboarding?.major?.length > 0 ? userDetails?.onboarding.major.map(item =>
                                    <Badge bg="primary" className='badge-item'>{item}</Badge>
                                ) : <p className='form-value'>N/A</p>}
                            </td>
                            <th>
                                <p className='form-title'>Minor:</p>
                            </th>
                            <td>
                                {userDetails?.onboarding?.minor?.length > 0 ? userDetails?.onboarding.minor.map(item =>
                                    <Badge bg="primary" className='badge-item'>{item}</Badge>
                                ) : <p className='form-value'>N/A</p>}
                            </td>
                        </tr>

                        <tr>
                            <th>
                                <p className='form-title'>Status:</p>
                            </th>
                            <td>
                                {userDetails?.onboarding?.status?.length > 0 ? userDetails?.onboarding.status.map(item =>
                                    <Badge bg="primary" className='badge-item'>{item}</Badge>
                                ) : <p className='form-value'>N/A</p>}
                            </td>
                            <th>
                                <p className='form-title'>Company Type:</p>
                            </th>
                            <td>
                                {userDetails?.onboarding?.company_type?.length > 0 ? userDetails?.onboarding.company_type.map(item =>
                                    <Badge bg="primary" className='badge-item'>{item}</Badge>
                                ) : <p className='form-value'>N/A</p>}
                            </td>
                        </tr>
                    </tbody>
                </Table>
                <br />
                <hr />
            </>
        )
    }

    return (
        <Row className="mt-4">
            <h5> User not present!</h5>
        </Row>
    )
}

export default UserPlanDetails
