import { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Container, Table, Form, Button, Toast } from 'react-bootstrap'
import constants from '../../../../helper/constants'
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import './user-extension-form.scss'
import { isValidNumber } from '../../../../helper'
import { recordExtension } from '../../../../redux/actions/user-course-extension'

function UserExtensionForm({ userDetails, courseKey, closeUserExtensionForm, updateUserDetails, paymentDetails, readonly = false }) {

    const [days, setDays] = useState('')
    const [disableDays, setDisableDays] = useState(false)
    const [price, setPrice] = useState('')
    const [lifetimeAccess, setLifetimeAccess] = useState(false)
    const [emiOptions, setEmiOptions] = useState(null)
    const [emiOpted, setEmiOpted] = useState(false)
    const [noOfEmis, setNoOfEmis] = useState(3)
    const [toast, setToast] = useState({
        message: 'Careerninja Admin',
        show: false,
        type: 'secondary'
    })
    const [cv, setCv] = useState(false)
    const [certificate, setCertificate] = useState(false)

    const dispatch = useDispatch()

    const [state, setState] = useState({
        // cv: false,
        // certificate: false,
        // razorpay_order_id: '',
        type: 'NA',
        email: userDetails.email,
        scheme: 'NA',
        paid_amount: '',
        refunded_amount: '',
        payment_date: new Date(),
        course_key: courseKey,
        currency: 'INR',
        payment_method: 'RAZORPAY',
        emi_no: 0,
        note: '',
        leadOwner: '',
        customPlan: '',
    })

    useEffect(() => {
        const emis = []
        if (userDetails?.subscription?.emi) {
            for (let i = 0; i < userDetails.subscription.emi; i++) {
                emis.push(<option key={`${i}__${i + 1}_`} value={i + 1}>{i + 1}</option>)
            }
            setEmiOptions(emis)
        }

        // console.log(userDetails?.subscription.price)
        // if (userDetails?.subscription && userDetails?.subscription?.price)
        //     setPrice(userDetails.subscrition.price)

    }, [])


    useEffect(() => {
        if (paymentDetails) {
            setState({
                type: paymentDetails.type,
                scheme: paymentDetails.scheme,
                paid_amount: paymentDetails.paid_amount,
                refunded_amount: paymentDetails.refunded_amount,
                payment_date: new Date(paymentDetails.payment_date),
                currency: paymentDetails.currency,
                payment_method: paymentDetails.payment_method,
                emi_no: paymentDetails.emi_no,
                note: paymentDetails.note
            })

            setDays(paymentDetails.days_extended)
            if (paymentDetails.days_extended > 1000)
                setLifetimeAccess(true)

            setCertificate(paymentDetails.certificate ?? false)
            setCv(paymentDetails.cv ?? false)
        }
    }, [])

    const toggleLifetimeAccess = (e) => {
        e && e.stopPropagation()
        setLifetimeAccess(e.target.checked)
        setDisableDays(e.target.checked)
    }

    const changeHandler = (e) => {
        e && e.stopPropagation()
        const name = e.target.name
        const value = e.target.value

        switch (name) {
            case 'paid_amount':
                if (isValidNumber(value))
                    setState({ ...state, [name]: Number(value) })
                break
            case 'refunded_amount':
                if (isValidNumber(value))
                    setState({ ...state, [name]: Number(value) })
                break
            default:
                setState({ ...state, [name]: value })
                break;
        }
    }

    const isValidData = () => {
        let flag = true

        if (type === 'NA' || scheme === 'NA') {
            flag = false
            setToast({ show: true, message: 'Please select appropriate transaction type and plan', type: 'danger' })
        }

        if (type === 'REFUND' && (state.refunded_amount === '' || state.refunded_amount < 10)) {
            flag = false
            setToast({ show: true, message: `Amount refunded can't be less than 10INR`, type: 'danger' })
        }

        if (type !== 'REFUND' && (state.paid_amount < 10 || state.paid_amount === '')) {
            flag = false
            setToast({ show: true, message: `Amount paid can't be less than 10INR`, type: 'danger' })
        }

        if (type === 'BOOK' && price === '') {
            flag = false
            setToast({ show: true, message: `Please enter plan amount`, type: 'danger' })
        }

        if (type === 'BOOK' && emiOpted && Number(noOfEmis) === 0) {
            flag = false
            setToast({ show: true, message: `No of EMI's should be at least 1`, type: 'danger' })
        }

        if (type === 'EMI' && userDetails.subscription.emi && state.emi_no === 0) {
            flag = false
            setToast({ show: true, message: `Please select a valid EMI no`, type: 'danger' })
        }

        if (state.note.length < 5) {
            flag = false
            setToast({ show: true, message: `Note can't be less than 2 words`, type: 'danger' })
        }
        if (leadOwner === "") {
            flag = false
            setToast({ show: true, message: `Lead Owner is a mandatory field`, type: 'danger' })
        }
        if (scheme === "CUSTOM_PLAN" && customPlan === "") {
            flag = false
            setToast({ show: true, message: `custom Plan name is a mandatory field`, type: 'danger' })
        }


        return flag

    }

    const recordTransaction = () => {
        if (isValidData()) {
            let localstate = { ...state }

            if (state.scheme === "CUSTOM_PLAN") {
                localstate = { ...state, scheme: `${state.scheme}||${state.customPlan.toUpperCase()}` }
            }

            console.log(localstate)

            // CLEANING DATA
            if (type !== 'EMI')
                localstate['emi_no'] = undefined

            if (type === 'REFUND')
                localstate['paid_amount'] = undefined

            if (type !== 'REFUND')
                localstate['refunded_amount'] = undefined

            const data = {
                transaction_details: localstate,
                emi_opted: emiOpted,
                lifetime_access: lifetimeAccess,
                extension_days: days === '' ? 0 : days,
                price: price,
                no_of_emis: noOfEmis,
                cv: cv,
                certificate: certificate
            }

            dispatch(recordExtension(data, resobj => {
                if (resobj.message === "Success") {
                    closeUserExtensionForm()
                    setToast({ show: true, message: 'Payment recorderded succcessfully!', type: 'success' })
                    updateUserDetails()
                }
            }, errobj => {
                setToast({ show: true, message: 'Error in recording payment', type: 'danger' })
            }))
        }
    }

    const { type, email, scheme, paid_amount, refunded_amount, payment_date, currency, payment_method, emi_no, note, leadOwner, customPlan } = state

    return (
        <Container className="transaction-wrapper">

            <Toast onClose={() => setToast({ ...toast, show: false })} show={toast.show} style={{ position: 'fixed', top: '60px', right: '30px' }} delay={3000} autohide bg={toast.type}>
                <Toast.Body className="text-white">{toast.message}</Toast.Body>
            </Toast>

            <Table className="mt-5" borderless={true}>
                <tbody>
                    <tr>
                        <th className="required-field">Transaction Type</th>
                        <td colSpan={3}>
                            <Form.Select value={type} name="type" className="w-25" onChange={changeHandler} disabled={readonly} >
                                <option value="NA">Select</option>
                                <option value="BOOK">Course Plan Booking</option>
                                <option value="EMI">EMI Payment</option>
                                <option value="REFUND">Refund Payment</option>
                            </Form.Select>
                        </td>
                    </tr>

                    <tr>
                        <th className="required-field">Plan</th>
                        <td colSpan={2}>
                            <Form.Select value={scheme} onChange={changeHandler} name="scheme" className="w-50" disabled={readonly}>
                                <option value="NA">Select</option>
                                {constants.DM_PLANS.map((el, id) => <option value={el} key={`${el}__${id}w__we`}>{el.replaceAll('_', ' ').toProperCase()}</option>)}
                            </Form.Select>
                        </td>
                        {scheme === "CUSTOM_PLAN" && (<>
                            <th className='required-field'>
                                Custom Plan Name:
                            </th>
                            <td>
                                <Form.Control as="input" name="customPlan" aria-label="With input" value={customPlan} onChange={changeHandler} />
                            </td>
                        </>)}
                    </tr>
                    <tr>
                        <th className='required-field'>Lead Owner</th>
                        <td colSpan={1}>
                            <Form.Control as="input" name="leadOwner" aria-label="With input" value={leadOwner} onChange={changeHandler} />
                        </td>
                    </tr>

                    <tr>
                        <th>Email</th>
                        <td>
                            {email}
                        </td>

                        <th className="required-field">Extend Access (in days)</th>
                        <td>
                            <div className="d-flex">
                                <Form.Control type="email" value={days}
                                    maxLength={3}
                                    onChange={e => {
                                        if (isValidNumber(e.target.value))
                                            setDays(Number(e.target.value))
                                    }}
                                    disabled={disableDays || readonly} className="w-25" />
                                <Form.Group className="d-flex">
                                    <Form.Check inline type="checkbox" label="Lifetime Access" checked={lifetimeAccess} onChange={toggleLifetimeAccess} style={{ marginLeft: '14px' }} />
                                </Form.Group>
                            </div>
                        </td>
                    </tr>

                    <tr>
                        <th>Premium CV Access</th>
                        <td>
                            <Form.Check
                                type="switch"
                                id="custom-switch"
                                name="cv"
                                label={cv ? "Unlocked" : "Locked"}
                                value={cv}
                                onChange={(e) => setCv(e.target.checked)}
                                disabled={readonly}
                            />
                        </td>

                        <th>Premium Certificate Access</th>
                        <td>
                            <Form.Check
                                type="switch"
                                id="custom-switch-2"
                                name="certificate"
                                label={certificate ? "Unlocked" : "Locked"}
                                value={certificate}
                                onChange={(e) => setCertificate(e.target.checked)}
                                disabled={readonly}
                            />
                        </td>
                    </tr>

                    <tr>
                        <th className="required-field">Plan Amount</th>
                        <td>
                            <Form.Control type="number" value={price} disabled={type !== 'BOOK' || readonly}
                                onChange={e => {
                                    if (isValidNumber(e.target.value))
                                        setPrice(Number(e.target.value))
                                }}
                                placeholder="₹" />
                        </td>
                        {type === 'REFUND' ? (
                            <>
                                <th className="required-field">Refund Amount</th>
                                <td>
                                    <Form.Control type="text" value={refunded_amount} name="refunded_amount" placeholder="₹" onChange={changeHandler} />
                                </td>
                            </>
                        ) : (
                            <>
                                <th className="required-field">Amount Paid</th>
                                <td>
                                    <Form.Control type="text" value={paid_amount} name="paid_amount" placeholder="₹" onChange={changeHandler} />
                                </td>
                            </>
                        )}
                    </tr>

                    {type === 'BOOK' && (
                        <tr>
                            <th>Opted for EMI</th>
                            <td>
                                <Form.Check
                                    type="switch"
                                    id="custom-switch-2"
                                    name="emi-optin"
                                    value={emiOpted}
                                    label={emiOpted ? "EMI Opted" : "EMI Not Opted"}
                                    onChange={e => setEmiOpted(e.target.checked)}
                                />
                            </td>

                            {emiOpted && (
                                <>
                                    <th>No of EMI's</th>
                                    <td>
                                        <Form.Control type="email" value={noOfEmis}
                                            maxLength={1}
                                            onChange={e => {
                                                if (isValidNumber(e.target.value))
                                                    setNoOfEmis(e.target.value)
                                            }}
                                            className="w-25" />
                                    </td>
                                </>
                            )}
                        </tr>
                    )}

                    <tr>
                        <th>Currency</th>
                        <td>
                            <Form.Select value={currency} onChange={changeHandler} name="currency" className="w-50">
                                {constants.CURRENCY.map((el, id) => <option value={el} key={`${el}__${id}w__ce`}>{el}</option>)}
                            </Form.Select>
                        </td>


                        <th>Payment Method</th>
                        <td>
                            <Form.Select value={payment_method} onChange={changeHandler} name="payment_method">
                                {constants.PAYMENT_METHODS.map((el, id) => <option value={el} key={`${el}__${id}w__we`}>{el}</option>)}
                            </Form.Select>
                        </td>
                    </tr>


                    <tr>
                        <th>Payment Date</th>
                        <td>
                            <DatePicker selected={payment_date} className="datepicker" onChange={(date) => setState({ ...state, payment_date: date })} />
                        </td>

                        {type !== 'BOOK' && (
                            <>
                                <th>EMI Number</th>
                                <td>
                                    <Form.Select value={emi_no} onChange={changeHandler} className="w-25" name="emi_no">
                                        <option value={0}>Select</option>
                                        {emiOptions}
                                    </Form.Select>
                                </td>
                            </>
                        )}
                    </tr>


                    <tr>
                        <th className="required-field">Note (min 3 words)</th>
                        <td colSpan={4}>
                            <Form.Control as="textarea" name="note" aria-label="With textarea" value={note} onChange={changeHandler} />
                        </td>
                    </tr>
                </tbody>
            </Table>

            <div className="d-flex justify-content-end w-100">
                <Button variant="primary" onClick={closeUserExtensionForm}>Cancel</Button>
                <Button variant="primary" className="save-btn" style={{ marginLeft: '24px' }} onClick={recordTransaction}>Save</Button>
            </div>

        </Container>
    )
}

export default UserExtensionForm
