import React, { useState } from 'react'
import { useEffect } from 'react';
import { Container, Breadcrumb, Col, Form, Row, Button, Toast, Spinner, Accordion, Card } from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import Select from 'react-select'
import { getRouteMap, getCourseDetails } from '../../redux/actions/admin-route-map-generator'
import './routeMapGenerator.scss'

export default function RouteMapGenerator() {
    const dispatch = useDispatch();
    const [courseDetails, setCourseDetails] = useState(null);
    const [course, setCourse] = useState("")
    const [stage, setStage] = useState([])
    const [status, setStatus] = useState([])
    const [companyType, setCompanyType] = useState(["NA"])
    const [goal, setGoal] = useState({ primary: "", secondary: "" })
    const [majorMinor, setMajorMinor] = useState({ major: [], minor: [] })
    const [toast, setToast] = useState({ show: false, type: "danger", message: "" })
    const [pathway, setPathway] = useState(null)
    const [spinner, setSpinner] = useState(false)

    useEffect(() => {
        dispatch(getCourseDetails((success) => setCourseDetails(success.courses)))
    }, [])


    function setGoalHandler({ type, value }) {
        if (type === "Primary") {
            setGoal(prev => ({ ...prev, primary: value }))
        }
        else if (type === "Secondary") {
            setGoal(prev => ({ ...prev, secondary: value }))
        }
    }

    function setMajorMinorHandler({ type, value }) {
        if (value) {

            if (type === "Major") {
                setMajorMinor(prev => ({ ...prev, major: [value] }))
            }
            else if (type === "Minor") {
                let minorArr = value.map(item => item.value)
                setMajorMinor(prev => ({ ...prev, minor: minorArr }))
            }
        }
    }

    function minorOptions(data) {
        return data.map(item => ({ value: item, label: item }))
    }

    function validateData() {
        if (course === "") {
            setToast({ ...toast, show: true, message: "enter course name!!" })
        }
        else if (stage.length === 0) {
            setToast({ ...toast, show: true, message: "select stage!!" })
        }
        else if (status.length === 0) {
            setToast({ ...toast, show: true, message: "select status!!" })
        }
        else if ((goal.primary === "" || goal.secondary === "") || goal.primary === goal.secondary) {
            setToast({ ...toast, show: true, message: "set goal!!" })
        }
        else if ((stage.includes("Intermediate") || stage.includes("Advanced")) && (majorMinor.major.length < 1 || majorMinor.minor.length < 2 || majorMinor.minor.includes(majorMinor.major[0]))) {
            setToast({ ...toast, show: true, message: "set major/minor!!" })
        }
        else {
            console.log((goal.primary === "" || goal.secondary === "") || goal.primary === goal.secondary)
            let data = {
                parameters: {
                    stage,
                    major: majorMinor.major,
                    minor: majorMinor.minor,
                    goal: [goal.primary, goal.secondary],
                    status,
                    company_type: companyType
                },
                course
            }
            setSpinner(true)
            dispatch(getRouteMap(data, (success) => {
                setPathway(success.pathwayGenerated)
                setSpinner(false)
            }))
            resetData()
        }
    }

    function resetData() {
        setCourse("")
        setStage([])
        setStatus([])
        setCompanyType(["NA"])
        setGoal({ primary: "", secondary: "" })
        setMajorMinor({ major: [], minor: [] })
        setPathway(null)
    }


    return (
        <Container fluid>
            <Breadcrumb>
                <Breadcrumb.Item active> Route Map Generator</Breadcrumb.Item>
            </Breadcrumb>
            <Toast
                onClose={() => setToast({ ...toast, show: false })}
                show={toast.show}
                style={{
                    position: "fixed",
                    top: "60px",
                    right: "30px",
                    zIndex: "999999999",
                }}
                delay={3000}
                autohide
                bg={toast.type}
            >
                <Toast.Body className="text-white">{toast.message}</Toast.Body>
            </Toast>


            {courseDetails ? <div className="path-options">
                <Row>
                    <Col>
                        <Form.Group>
                            <Form.Label>Select Course</Form.Label>
                            <Form.Select onChange={(e) => setCourse(e.target.value)} value={course}>
                                <option value={""}>Select...</option>
                                {courseDetails.map(item => <option value={item.key} key={item.key}>{item.name}</option>)}
                            </Form.Select>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Select Stage</Form.Label>
                            <Form.Select onChange={(e) => setStage([`${e.target.value}`])} value={stage}>
                                <option>Select...</option>
                                {courseDetails[0].parameters.stage.map(item => <option value={item} key={item}>{item}</option>)}
                            </Form.Select>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Select Status</Form.Label>
                            <Form.Select onChange={(e) => setStatus([`${e.target.value}`])} value={status}>
                                <option>Select...</option>
                                {courseDetails[0].parameters.status.map(item => <option value={item} key={item}>{item}</option>)}
                            </Form.Select>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Select Company Type</Form.Label>
                            <Form.Select onChange={(e) => setCompanyType([`${e.target.value}`])} value={companyType}>
                                <option value={"NA"} key={"NA"}>NA</option>
                                {courseDetails[0].parameters.company_type.map(item => <option value={item} key={item}>{item}</option>)}
                            </Form.Select>
                        </Form.Group>
                    </Col>
                    <Col>
                        <h5 className='route-map__heading'>Select Goals -</h5>
                        <Form.Group>
                            <Form.Label>Select Primary goal</Form.Label>
                            <Form.Select onChange={(e) => setGoalHandler({ type: 'Primary', value: e.target.value })} value={goal.primary}>
                                <option value={""}>Select...</option>
                                {courseDetails[0].parameters.goal
                                    .map(item => <option value={item} key={item}>{item}</option>)}
                            </Form.Select>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Select Secondary goal</Form.Label>
                            <Form.Select onChange={(e) => setGoalHandler({ type: 'Secondary', value: e.target.value })} value={goal.secondary}>
                                <option value={""}>Select...</option>
                                {courseDetails[0].parameters.goal
                                    .map(item => <option value={item} key={item} >{item}</option>)}
                            </Form.Select>
                        </Form.Group>
                        {goal.primary === goal.secondary && goal.primary !== "" && <span style={{ color: 'red' }}><small>primary and secondary goals cannot be same!!</small></span>}
                    </Col>
                    {(stage.includes("Intermediate") || stage.includes("Advanced")) &&
                        <Col>
                            <h5 className='route-map__heading'>Select Major/Minors -</h5>
                            <Form.Group>
                                <Form.Label>Select Major</Form.Label>
                                <Form.Select onChange={(e) => setMajorMinorHandler({ type: 'Major', value: e.target.value })} value={majorMinor.major}>
                                    <option value={null}>Select</option>
                                    {courseDetails[0].parameters.major
                                        .map(item => <option value={item} key={item}>{item}</option>)}
                                </Form.Select>
                            </Form.Group>
                            <Form.Group>
                                <Form.Label style={{ zIndex: 2 }}>Select Minors (2)</Form.Label>
                                <Select
                                    isMulti
                                    name="minor"
                                    options={majorMinor.minor.length >= 2 ? minorOptions(majorMinor.minor) : minorOptions(courseDetails[0].parameters.minor)}
                                    onChange={(e) => setMajorMinorHandler({ type: 'Minor', value: e })}
                                />
                            </Form.Group>
                            {majorMinor.minor.includes(majorMinor.major[0]) && <span style={{ color: 'red' }}><small>Major and minors can't be same!!</small></span>}
                        </Col>
                    }
                    <Col className='route-map__button__col'>
                        <div className="route-map__button">
                            <Button variant='primary' onClick={validateData}>Generate Pathway</Button>
                        </div>
                        <div className="route-map__button">
                            <Button variant='danger' onClick={resetData}>Reset</Button>
                        </div>
                    </Col>
                </Row>
            </div> : <p>Please wait fetching data....</p>}

            {spinner && <Spinner animation="border" role="status" size='3x' />}
            {pathway && <div>
                <hr />
                <Accordion>
                    {Object.keys(pathway).map((phase, phaseIndex) => (
                        <Accordion.Item eventKey={phaseIndex}>
                            <Accordion.Header>{`PHASE - ${phase}`}</Accordion.Header>
                            <Accordion.Body>
                                <Accordion>
                                    {Object.keys(pathway[phase]).map((module, moduleIndex) => (
                                        <Accordion.Item eventKey={`${phaseIndex}_${moduleIndex}`}>
                                            <Accordion.Header>{`MODULE - ${pathway[phase][module].name}`}</Accordion.Header>
                                            <Accordion.Body>
                                                <Accordion>
                                                    {Object.keys(pathway[phase][module]).map((level, levelIndex) => {
                                                        if (level !== 'name') {
                                                            return (
                                                                <Accordion.Item eventKey={`${phaseIndex}_${moduleIndex}_${levelIndex}`}>
                                                                    <Accordion.Header>{`LEVEL - ${pathway[phase][module][level].levelName}`}</Accordion.Header>
                                                                    <Accordion.Body>
                                                                        {pathway[phase][module][level].content.map(content => (
                                                                            <Card style={{ margin: '1rem 0rem', background: '#fcfff7' }}>
                                                                                <Card.Body>
                                                                                    <Card.Subtitle className="mb-2 text-muted">{content.content_type}</Card.Subtitle>
                                                                                    <Card.Text>
                                                                                        {content.meta.title}
                                                                                    </Card.Text>
                                                                                    {content.meta.link && <Card.Link href={`${content.meta.link}`} target={"_blank"}>content link</Card.Link>}
                                                                                </Card.Body>
                                                                            </Card>
                                                                        ))}
                                                                    </Accordion.Body>
                                                                </Accordion.Item>
                                                            )
                                                        }
                                                    })}
                                                </Accordion>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    ))}
                                </Accordion>
                            </Accordion.Body>
                        </Accordion.Item>
                    ))}
                </Accordion>
            </div>}
        </Container>
    )
}