import { axiosAuth } from "../../config/axios-instances";
import { ADMIN_API } from "../../config/config";
import { Api_url } from "../../config/api_url";

export function unlockModule(data, successCallback, errorCallback) {
    return async function (dispatch) {
        try {
            const response = await axiosAuth.post(
                ADMIN_API + Api_url.UnlockUserModule,
                data
            );
            successCallback &&
                successCallback({
                    ...response.data.data[0],
                    user_found: response.data.data.length > 0,
                });
        } catch (err) {
            console.log(err);
            errorCallback && errorCallback(err.response.data);
        }
    };
}

export function getPricingPlans(data, successCallback, errorCallback) {
    return async function () {
        try {
            const response = await axiosAuth.get(ADMIN_API + Api_url.GetPricingPlan, { params: { courseKey: data } });
            successCallback && successCallback(response.data.data)
        } catch (err) {
            console.log(err)
            errorCallback && errorCallback(err.response.data)
        }

    }
}

export function getUserPricingUnlock(data, successCallback, errorCallback) {
    return async function () {
        try {

            const response = await axiosAuth.get(ADMIN_API + Api_url.GetUserPricingUnlock, { params: { userEmail: data } })
            successCallback && successCallback(response.data.data)

        } catch (err) {
            console.log(err)
            errorCallback && errorCallback(err.response.data)
        }
    }
}

export function setUserPricingUnlock(data, successCallback, errorCallback) {
    return async function () {
        try {

            const response = await axiosAuth.post(ADMIN_API + Api_url.SetUserPricingUnlock, { data })
            successCallback && successCallback(response.data.data)

        } catch (err) {
            console.log(err)
            errorCallback && errorCallback(err.response.data)
        }
    }
}
