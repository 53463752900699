
//authentication
export const USER_AUTH = 'USER_AUTH'

export const ADMIN_USERS = 'ADMIN_USERS'
export const SHOW_MODAL = 'SHOW_MODAL'
export const PAID_USERS = 'PAID_USERS'
export const SHOW_MENTOR_MODAL = 'SHOW_MENTOR_MODAL'
export const MENTOR_DETAILS = 'MENTOR_DETAILS'
export const SET_ACCESS_KEY = 'SET_ACCESS_KEY'

