import { useSelector} from 'react-redux'
import { Breadcrumb, Alert, Container, } from 'react-bootstrap'
import './dashboard.scss'
import Icon from '../../utils/icon'

function Dashboard() {

  const user_role = useSelector(store => store.authentication.role)


  return (
    <Container fluid>
      <Breadcrumb>
        <Breadcrumb.Item active>Dashboard</Breadcrumb.Item>
      </Breadcrumb>

      <Alert variant="info" className="d-flex">
        <a variant="primary" href="https://charts.mongodb.com/charts-cn-production-izkvb/dashboards/77d3a034-37c6-4c46-b37c-b259176238de" target="_blank" className="chart-btn">
          <Icon icon="ICON_MONGO_LOGO" />
          Chart Dashboard
        </a>

        <a variant="primary" href="https://docs.google.com/spreadsheets/d/1mZYK87G2OV0OLPiaryF8yy9gjp827j2DKSpgsSJNEYU/edit#gid=0" target="_blank" className="chart-btn" style={{ marginLeft: '26px' }}>
          <img src={'/img/gsheet-icon.svg'} />
          Broken Links
        </a>
      </Alert>

    </Container>
  )
}

export default Dashboard
