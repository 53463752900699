import { axiosNoAuth } from '../../config/axios-instances';
import { ADMIN_API } from '../../config/config';
import { PURGE } from 'redux-persist';
import { USER_AUTH } from '../types';
import { Api_url } from '../../config/api_url';
import { SET_ACCESS_KEY } from "../types"

export function login(data, successCallback, errorCallback) {
  return async function (dispatch) {
    try {
      const response = await axiosNoAuth.post(ADMIN_API + Api_url.Authentication, data);
      await dispatch({
        type: USER_AUTH,
        payload: response.data.data,
      });
      successCallback && successCallback(response.data.data);
    } catch (err) {
      console.log(err)
      errorCallback && errorCallback(err.response.data)
    }
  }
}

export function logout(successCallback, errorCallback) {
  return async function (dispatch) {
    try {
      await dispatch({
        type: PURGE,
        key: 'cnadroot',
        result: () => null,
      });
      await localStorage.clear();
      successCallback && successCallback();

    } catch (e) {
      // await dispatch({ type: LOGOUT });
      console.error(e);
      errorCallback && errorCallback(e.response.data.message);
    }
  };
}

export function setAccessKeyProvided(data) {
  return async function (dispatch) {
    dispatch({
      type: SET_ACCESS_KEY,
      payload: data,
    })
  }
}