import React from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { axiosAuthMiddleware } from './middleware/axios-middleware'
import './styles/index.scss'
import { createStore, applyMiddleware } from 'redux'
import reduxThunk from 'redux-thunk'
import { Provider } from 'react-redux'
import reducers from './redux/reducers'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2'
import { PersistGate } from 'redux-persist/lib/integration/react'
import Routes from './config/routes'
import Login from './views/Login'
import PageLayout from './layouts/PageLayout'

const persistConfig = {
  key: 'cnadroot',
  storage: storage,
  timeout: null,
  stateReconciler: autoMergeLevel2
}

const pReducer = persistReducer(persistConfig, reducers)

const createStoreWithMiddleware = applyMiddleware(
  reduxThunk,
  axiosAuthMiddleware
)(createStore)

const store = createStoreWithMiddleware(
  pReducer,
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__()
)

const persistor = persistStore(store)

String.prototype.toProperCase = function () {
  return this.replace(/\w\S*/g, function (txt) { return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase(); });
};

class App extends React.Component {
  render() {
    return (
      <Provider store={store}>
        <PersistGate loading persistor={persistor}>
          <Router>
            <Switch>
              <Route path={Routes.Login} component={Login} />
              <Route exact path={[Routes.Main, Routes.Login]} component={Login} />
              <Route
                path={
                  [Routes.Dashboard,
                  Routes.CourseMasterSheet,
                  Routes.UserCourseExtension,
                  Routes.TransactionDetails,
                  Routes.UserControl,
                  Routes.UserUnlockModule,
                  Routes.MicroCourses,
                  Routes.RouteMapGenerator,
                  Routes.DataExports,
                  Routes.PopQuiz,
                  ]} component={PageLayout} />
            </Switch>
          </Router>
        </PersistGate>
      </Provider>
    )
  }
}

export default App
