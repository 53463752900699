import { axiosAuth } from "../../config/axios-instances";
import { Api_url } from "../../config/api_url";
import { ADMIN_API } from "../../config/config";

export function getRouteMap(data, successCallback, errorCallback) {
    return async function () {
        try {
            const response = await axiosAuth.get(ADMIN_API + Api_url.RouteMapGenerator, { params: data })
            successCallback && successCallback(response.data.data);
        } catch (e) {
            console.log(e)
            errorCallback && errorCallback(e.response.data)
        }
    }
}

export function getCourseDetails(successCallback, errorCallback) {
    return async function () {
        try {
            const response = await axiosAuth.get(ADMIN_API + Api_url.GetCourseDetails)
            successCallback && successCallback(response.data.data);
        } catch (e) {
            console.log(e)
            errorCallback && errorCallback(e.response.data)
        }
    }
}
