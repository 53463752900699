const Routes = {
  Login: "/login",
  Main: "/",
  Dashboard: "/dashboard",

  //admin users
  UserControl: "/user-control",
  UserCourseExtension: "/course-extension",
  TransactionDetails: "/transaction-details",
  CourseMasterSheet: "/course-mastersheet",
  UserUnlockModule: "/unlock-module",
  MicroCourses: "/microcourses",
  RouteMapGenerator: "/route-map",
  DataExports: "/exports",
  PopQuiz: "/popquiz",
};

export default Routes;
