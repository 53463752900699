import { axiosNoAuth } from '../../config/axios-instances';
import { ADMIN_API } from '../../config/config';
import { Api_url } from '../../config/api_url';

export function getMicroCourse(data, successCallback, errorCallback) {
    return async function (dispatch) {
        try {
            const response = await axiosNoAuth.post(ADMIN_API + Api_url.GetMicroCourse, data);
            successCallback && successCallback(response.data.data);
        } catch (err) {
            console.log(err)
            errorCallback && errorCallback(err.response.data)
        }
    }
}

export function getAllMicroCourses(data, successCallback, errorCallback) {
    return async function (dispatch) {
        try {
            const response = await axiosNoAuth.post(ADMIN_API + Api_url.GetAllMicroCourses, data);
            console.log(response.data.data);
            successCallback && successCallback(response.data.data);
        } catch (err) {
            console.log(err)
            errorCallback && errorCallback(err.response.data)
        }
    }
}

export function createMicroCourse(data, successCallback, errorCallback) {
    return async function (dispatch) {
        try {
            const response = await axiosNoAuth.post(ADMIN_API + Api_url.CreateMicroCourse, data);
            successCallback && successCallback(response.data.data);
        } catch (err) {
            console.log(err)
            errorCallback && errorCallback(err.response.data)
        }
    }
}