import { useState } from 'react';
import { Button, Modal, Form } from 'react-bootstrap'
import { useHistory } from "react-router-dom";
import { useDispatch } from 'react-redux';
import { setAccessKeyProvided } from '../redux/actions/authentication'

const AccessKeyModal = ({ showModal }) => {
    const [show, setShow] = useState(showModal);
    const [enteredAccessKey, setEnteredAccessKey] = useState(null);
    const [showError, setShowError] = useState(false);
    const history = useHistory();
    const dispatch = useDispatch();

    const handleClose = () => setShow(false);

    const handleAccessKeyChange = (e) => {
        setEnteredAccessKey(e.target.value);
    }

    const handleAccessKeyCheck = (e) => {
        if (enteredAccessKey === process.env.REACT_APP_ACCESS_KEY) {
            handleClose();
            setShowError(false);
            dispatch(setAccessKeyProvided(true));
        } else {
            setShowError(true);
        }
    }

    const handleGoToDashboard = (e) => {
        history.push("/dashboard");
    }

    return (
        <>
            <Modal show={show} backdrop="static" keyboard={false}>
                <Modal.Header>
                    <Modal.Title>Admin Access-Key Required</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group className="mb-3" controlId="formBasicPassword">
                        <Form.Control required onChange={handleAccessKeyChange} type="password" />
                    </Form.Group>
                    {showError && <p className="px-2" style={{ color: "red", fontWeight: "bold", fontSize: "12px" }}>Incorrect Access-Key</p>}
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={handleGoToDashboard} variant="outline-secondary">Go To Dashboard</Button>
                    <Button type="submit" onClick={handleAccessKeyCheck} variant="primary">Submit</Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default AccessKeyModal;