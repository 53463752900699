export const Api_url = {
  //login
  Authentication: "/auth/",

  // user course extension
  VerifyUserCourse: "/extension/verify-user/",
  RecordExtension: "/extension/record/",
  DeleteUser: "/extension/delete-user/",
  GetPaymentRecord: "/transaction/",
  LockUser: "/extension/lock-user",
  GetAllTransactions: "/extension/transaction-details",
  GetUserPathwayDetails: '/extension/user-pathway',

  //admin panel user apis
  ShowAllAdminUsers: "/admin-user/list/users/",
  AddAdminUsers: "/admin/add/users/",
  ShowPaidUsers: "/dashboard/get/paid/users/",
  GetAdminUserDetails: "/admin-user/get/details/",
  AddUsertoMentor: "/activity/add/user/mentor/",
  DeleteUserFromMentor: "/activity/remove/user/mentor/",
  GetUserDetails:"/user-details",

  //payment link apis
  CreatePaymentLink: "/create/link/",

  //user unlock module
  UnlockUserModule: "/unlock-module",
  GetPricingPlan:'/pricing-unlock/plan',
  GetUserPricingUnlock:'/pricing-unlock',
  SetUserPricingUnlock:'/pricing-unlock/add',

  //microcourses
  GetMicroCourse: "/microcourse",
  GetAllMicroCourses: "/microcourse/all",
  CreateMicroCourse: "/microcourse/create",
  
  // route map generator
  RouteMapGenerator: '/route-map',
  GetCourseDetails:'/route-map/course-detail',

  //mcq pop quiz
  CreatePopQuiz: "/popquiz/create",
};
