import { axiosAuth } from '../../config/axios-instances';
import { ADMIN_API } from '../../config/config';
import {
  ADMIN_USERS, SHOW_MODAL, PAID_USERS, SHOW_MENTOR_MODAL, MENTOR_DETAILS
} from '../types';
import { Api_url } from '../../config/api_url';


export function getAdminUsers(successCallback, errorCallback) {
  return async function (dispatch) {
    try {
      let response = await axiosAuth.post(ADMIN_API + Api_url.ShowAllAdminUsers);
      await dispatch({
        type: ADMIN_USERS,
        payload: response.data,
      });
      successCallback && successCallback(response.data.data);
    } catch (e) {
      errorCallback && errorCallback(e.response.data)
    }
  }
}

export function addAdminUsers(data, successCallback, errorCallback) {
  return async function () {
    try {
      let response = await axiosAuth.post(ADMIN_API + Api_url.AddAdminUsers, data);
      successCallback && successCallback(response.data.data);
    } catch (e) {
      errorCallback && errorCallback(e.response.data)
    }
  }
}

export function getPaidUsers(successCallback, errorCallback) {
  return async function (dispatch) {
    try {
      let response = await axiosAuth.get(ADMIN_API + Api_url.ShowPaidUsers);
      await dispatch({
        type: PAID_USERS,
        payload: response.data,
      });
      successCallback && successCallback(response.data.data);
    } catch (e) {
      errorCallback && errorCallback(e.response.data)
    }
  }
}

export function getAdminUserDetails(data, successCallback, errorCallback) {
  return async function (dispatch) {
    try {
      let response = await axiosAuth.post(ADMIN_API + Api_url.GetAdminUserDetails, data);
      await dispatch({
        type: MENTOR_DETAILS,
        payload: response.data,
      });
      successCallback && successCallback(response.data.data);
    } catch (e) {
      errorCallback && errorCallback(e.response.data)
    }
  }
}

export function assignUserToMentor(data, successCallback, errorCallback) {
  return async function () {
    try {
      let response = await axiosAuth.post(ADMIN_API + Api_url.AddUsertoMentor, data);
      successCallback && successCallback(response.data.data);
    } catch (e) {
      errorCallback && errorCallback(e.response.data)
    }
  }
}

export function unAssignUserToMentor(data, successCallback, errorCallback) {
  return async function () {
    try {
      let response = await axiosAuth.post(ADMIN_API + Api_url.DeleteUserFromMentor, data);
      successCallback && successCallback(response.data.data);
    } catch (e) {
      errorCallback && errorCallback(e.response.data)
    }
  }
}

export function showModal(data) {
  return async function (dispatch) {
    await dispatch({
      type: SHOW_MODAL,
      payload: data
    })
  }
}

export function showMentorModal(data) {
  return async function (dispatch) {
    await dispatch({
      type: SHOW_MENTOR_MODAL,
      payload: data
    })
  }
}

export function getUserDetails(successCallback, errorCallback) {
  return async function (dispatch) {
    try {

      const response = await axiosAuth.get(ADMIN_API + Api_url.GetUserDetails);
      successCallback && successCallback(response.data.data)

    } catch (err) {
      console.log(err)
      errorCallback && errorCallback(err.response.data)
    }
  }
}

