import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Form, InputGroup, Card, Container, Col, Row, Alert, Modal, Spinner, ListGroup, ListGroupItem } from 'react-bootstrap';
import { getMicroCourse, getAllMicroCourses, createMicroCourse } from '../../redux/actions/microCourse';
import AccessKeyModal from '../../components/AccessKeyModal';
import * as XLSX from "xlsx";
import ReactJson from 'react-json-view'
import { Link } from 'react-router-dom'
import "./microcoursepage.scss";

const MicroCoursePage = () => {
    // const [microCourseSearch, setMicroCourseSearch] = useState('');
    // const [microCourseSearchResponse, setMicroCourseSearchResponse] = useState('');
    // const [microCourseSearchResponseError, setMicroCourseSearchResponseError] = useState('');
    const [allMicroCourses, setAllMicroCourses] = useState([]);
    const [showAllCoursesLoader, setShowAllCoursesLoader] = useState(false);
    const [selectedFile, setSelectedFile] = useState("");
    const [microCourseConfig, setMicroCourseConfig] = useState();
    const [newMicroCourseLayoutData, setNewMicroCourseLayoutData] = useState();
    const [uploadPreviewed, setUploadPreviewed] = useState(false);
    const [popQuizData, setPopQuizData] = useState();
    const [microCourseUploadResponse, setMicroCourseUploadResponse] = useState("");
    const [microCourseUploadResponseError, setMicroCourseUploadResponseError] = useState("");
    const [workBookContainsErrors, setWorkBookContainsErrors] = useState(false);
    const [showSubmitLoader, setShowSubmitLoader] = useState();
    const dispatch = useDispatch();

    const authentication = useSelector((store) => store?.authentication);

    // const handleMicroCourseSearchChange = (e) => {
    //     setMicroCourseSearch(e.target.value);
    // }

    // const handleSearch = (e) => {
    //     if (microCourseSearch !== "") {
    //         dispatch(getMicroCourse({ key: microCourseSearch },
    //             (res) => {
    //                 setMicroCourseSearchResponse(res);
    //             }, (err) => {
    //                 setMicroCourseSearchResponseError(err.errors);
    //             }));
    //     }
    // }

    const handleViewAllMicroCourses = (e) => {
        setShowAllCoursesLoader(true);
        dispatch(getAllMicroCourses(null,
            res => {
                setShowAllCoursesLoader(false);
                setAllMicroCourses(res);
            },
            err => {
                setShowAllCoursesLoader(false);
            }
        ));
    }

    const handleClearViewAll = (e) => {
        setAllMicroCourses([]);
    }

    const filePathChangeHandler = (e) => {
        setSelectedFile(e.target.files[0]);
    }

    const isWorkBookValid = (workBook) => {
        if (workBook) {
            if (workBook.SheetNames[0].includes("_") &&
                workBook.SheetNames[1].includes("_") &&
                workBook.SheetNames[1].includes("popquiz") &&
                workBook.Sheets[workBook.SheetNames[0]]['A1'].v === "id" &&
                workBook.Sheets[workBook.SheetNames[0]]['B1'].v === "title" &&
                workBook.Sheets[workBook.SheetNames[0]]['C1'].v === "description" &&
                workBook.Sheets[workBook.SheetNames[0]]['D1'].v === "link" &&
                workBook.Sheets[workBook.SheetNames[0]]['E1'].v === "contentType" &&
                workBook.Sheets[workBook.SheetNames[0]]['F1'].v === "quiz_id" &&
                workBook.Sheets[workBook.SheetNames[1]]['A1'].v === "id" &&
                workBook.Sheets[workBook.SheetNames[1]]['B1'].v === "question" &&
                workBook.Sheets[workBook.SheetNames[1]]['C1'].v === "correct_answer" &&
                workBook.Sheets[workBook.SheetNames[1]]['D1'].v === "choice_0" &&
                workBook.Sheets[workBook.SheetNames[1]]['E1'].v === "choice_1" &&
                workBook.Sheets[workBook.SheetNames[1]]['F1'].v === "choice_2" &&
                workBook.Sheets[workBook.SheetNames[1]]['G1'].v === "choice_3" &&
                workBook.Sheets[workBook.SheetNames[1]]['H1'].v === "quiz_id" &&
                workBook.Sheets[workBook.SheetNames[1]]['I1'].v === "hint"
            )
                return true;
        }

        return false;
    }

    const readSpreadSheet = () => {
        const reader = new FileReader();
        reader.onload = (evt) => {
            const binaryString = evt.target.result;
            const workBook = XLSX.read(binaryString, { type: "binary" });
            const miniCourseLayoutSheet = workBook.Sheets[workBook.SheetNames[0]];
            const popQuizSheet = workBook.Sheets[workBook.SheetNames[1]];

            if (isWorkBookValid(workBook)) {
                setWorkBookContainsErrors(false);
                setMicroCourseConfig({ name: workBook.SheetNames[0].split('_')[0], key: workBook.SheetNames[0].split('_')[1] })
                const miniCourseLayoutJson = XLSX.utils.sheet_to_json(miniCourseLayoutSheet);
                setNewMicroCourseLayoutData(miniCourseLayoutJson.filter((item) => item.length !== 0));

                const popQuizJson = XLSX.utils.sheet_to_json(popQuizSheet);
                setPopQuizData(popQuizJson.filter((item) => item.length !== 0));
            } else {
                setWorkBookContainsErrors(true);
                document.getElementById("microcourse-file").value = "";
                setUploadPreviewed(false);
                return;
            }
        };
        reader.readAsBinaryString(selectedFile);
        setSelectedFile(""); //! Dont Touch This
        setUploadPreviewed(true);
    }

    const handleUploadCancel = () => {
        setPopQuizData("");
        setMicroCourseConfig("");
        setNewMicroCourseLayoutData("");
        setUploadPreviewed(false);
        document.getElementById("microcourse-file").value = "";
    }

    const handleSubmitCourse = (e, overwrite) => {
        setShowSubmitLoader(true);
        dispatch(createMicroCourse(
            {
                config: microCourseConfig,
                courseLayout: newMicroCourseLayoutData,
                popQuiz: popQuizData,
                overWriteExisting: overwrite
            }, res => {
                setMicroCourseUploadResponse("Microcourse Successfully uploaded!")
                setShowSubmitLoader(false);
            }, err => {
                setMicroCourseUploadResponseError(err.errors)
                setShowSubmitLoader(false);
            })
        );
        setUploadPreviewed(false);
        setMicroCourseUploadResponse("");
        setMicroCourseUploadResponseError("");
    }

    const handleCloseUploadResponse = () => {
        setPopQuizData("");
        setMicroCourseConfig("");
        setNewMicroCourseLayoutData("");
        setUploadPreviewed(false);
        document.getElementById("microcourse-file").value = "";
        setMicroCourseUploadResponseError("");
        setMicroCourseUploadResponse("");
    }

    return (
        <div>
            <AccessKeyModal showModal={!authentication?.accessKeyEntered} />
            <h1><i className="fa fa-spa"></i> CareerNinja Micro-Courses</h1>
            <hr />
            {allMicroCourses.length == 0 && <Button className="mb-2" size="md" variant='success' onClick={handleViewAllMicroCourses}>
                <i className="fa fa-list-alt"></i> View All </Button>}
            {!allMicroCourses.length == 0 && <Button className="mb-2" size="md" variant='outline-secondary' onClick={handleClearViewAll}>Clear</Button>}
            {
                showAllCoursesLoader ?
                    <Spinner
                        className="mx-3 mt-1"
                        as="span"
                        variant="success"
                        animation="border"
                        size="lg"
                        role="status"
                        aria-hidden="true"
                    /> :
                    allMicroCourses.map((item, index) =>
                        <Card key={index} style={{ width: '18rem' }}>
                            <Card.Body>
                                <div className="d-flex flex-row justify-content-around">
                                    <Card.Title style={{ fontWeight: "bold" }}>{item.name} ({item.key})</Card.Title>
                                    <Button disabled variant="outline-success"><i className='fa fa-pen'></i></Button>
                                    <Button disabled variant="danger"><i className='fa fa-trash'></i></Button>
                                </div>
                                <ListGroup className="list-group-flush">
                                    <ListGroupItem>{item.layout?.length} course items</ListGroupItem>
                                    <ListGroupItem>Updated: {item["updated_at"].substring(0, 10)}</ListGroupItem>
                                </ListGroup>
                            </Card.Body>
                        </Card>)
            }

            <hr />
            {/* <Form>
                <h4>Check if Key/Name Exists</h4>
                <Form.Group>
                    <Row className='w-50'>
                        <InputGroup className="mb-3" controlId="formBasicText">
                            <Form.Control type="text" onChange={handleMicroCourseSearchChange} placeholder="Search micro-course by key or name" />
                            <Button variant='primary px-3'><i className='fa fa-search'
                                onClick={handleSearch}></i></Button>
                        </InputGroup>
                    </Row>
                </Form.Group>
            </Form>
            <p>{microCourseSearchResponseError !== "" ? microCourseSearchResponseError : microCourseSearchResponse}</p> 
            <hr />
            */}
            <h3>Upload/Update Micro-Course
                <Link style={{ fontSize: "14px" }} to="/files/mini_course_template.xlsx" target="_blank" download><i className="fa fa-download mx-2"></i>Download Template</Link>
            </h3>
            {workBookContainsErrors && <Alert variant="danger">
                <Alert.Heading>The uploaded XLSX file contains errors.
                    <Link className='mx-1' to="/files/mini_course_template.xlsx" target="_blank" download><i className="fa fa-download"></i>  Download Template</Link>
                </Alert.Heading>
            </Alert>}
            <Container className='mb-2'>

                <Row lg={3} className='mb-3 justify-content-center mx-2'>
                    <div className='d-flex upload-file-blob mb-2'>
                        <i className='fa fa-upload'> .XLSX File Upload</i>
                        <Form.Group>
                            <Form.Control
                                className='mb-2'
                                type="file"
                                id="microcourse-file"
                                accept=".xlsx"
                                onChange={filePathChangeHandler}
                            />
                        </Form.Group>
                    </div>
                </Row>
                <Button
                    size="md"
                    className='mb-3'
                    disabled={selectedFile === "" || uploadPreviewed}
                    variant='success'
                    onClick={readSpreadSheet}>
                    Preview JSON
                </Button>
                <Row lg={6} className="mb-3 justify-content-end">
                    {uploadPreviewed && !workBookContainsErrors &&
                        <Button
                            disabled={selectedFile !== ""}
                            size="md"
                            variant='danger'
                            onClick={handleUploadCancel}>
                            Cancel
                        </Button>}
                    {uploadPreviewed && !workBookContainsErrors &&
                        <Button
                            disabled={selectedFile !== "" || showSubmitLoader}
                            variant='outline-success'
                            size='md'
                            className="mx-3"
                            onClick={(e) => handleSubmitCourse(e, false)}>
                            {!showSubmitLoader ? <i className="fa fa-paper-plane"> Confirm & Submit</i>
                                :
                                <Spinner
                                    as="span"
                                    animation="border"
                                    size="lg"
                                    variant="success"
                                    role="status"
                                    aria-hidden="true"
                                >Submitting...</Spinner>
                            }
                        </Button>}
                </Row>
                <Row>
                    <ReactJson
                        iconStyle="square"
                        theme="google"
                        indentWidth={3}
                        src={{
                            coure_config: microCourseConfig,
                            course_layout: newMicroCourseLayoutData,
                            pop_quiz: popQuizData
                        }} />
                </Row>
            </Container>

            <Modal show={microCourseUploadResponseError?.includes("DUPLICATE")} onHide={handleCloseUploadResponse} backdrop="static" keyboard={false}>
                <Modal.Header>
                    <Modal.Title style={{ color: "red" }}>Microcourse Key/Name Already Exists</Modal.Title>
                </Modal.Header>
                <Modal.Footer>
                    <Button variant="secondary" size="lg" onClick={handleCloseUploadResponse}>
                        Close
                    </Button>
                    <Button type="submit" onClick={(e) => handleSubmitCourse(e, true)} size="lg" variant="danger">I understand, Overwrite</Button>
                </Modal.Footer>
            </Modal>
            <Modal show={microCourseUploadResponse !== ""} backdrop="static" keyboard={false}>
                <Modal.Header>
                    <Modal.Title style={{ color: "green" }}>{microCourseUploadResponse}</Modal.Title>
                </Modal.Header>
                <Modal.Footer>
                    <Button variant="secondary" size="lg" onClick={handleCloseUploadResponse}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </div >
    );
}

export default MicroCoursePage;