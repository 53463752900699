import { SET_ACCESS_KEY, USER_AUTH } from '../types';
import { PURGE } from 'redux-persist';

const initial_state = {
  token: null,
  accessKeyEntered: false
};

export default (state = initial_state, action) => {
  switch (action.type) {
    case PURGE:
      return {
        token: null,
        accessKeyEntered: false
      };

    case USER_AUTH:
      return {
        ...state,
        token: action.payload.token,
        role: action.payload.role,
        email: action.payload.email,
      };

    case SET_ACCESS_KEY:
      return {
        ...state,
        accessKeyEntered: action.payload
      }

    default:
      return state;
  }
};
