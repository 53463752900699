import React from 'react';
import { connect } from 'react-redux';
import { Route, Redirect, withRouter } from 'react-router-dom';
import Routes from './routes';

function PrivateRoute(props) {
	const Component = props.component;
	if (props.user_role === 'ADMIN' || props.user_role === 'MODERATOR' && props.token) {
		return (<Route {...props} path={props.path} component={Component} />)
	}
	else {
		return (<Redirect to={Routes.Login} />)
	}
}

function mapStateToProps(state) {
	return {
		token: state.authentication.token,
		user_role: state.authentication.role
	};
}

export default withRouter(
	connect(
		mapStateToProps,
		null
	)(PrivateRoute)
);
