import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { unlockModule, getPricingPlans, getUserPricingUnlock, setUserPricingUnlock } from "../../redux/actions/user-unlock-module";
import AccessKeyModal from '../../components/AccessKeyModal';
import { Button, Form, Container, Row, Col, Alert, FormCheck } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';

const UserUnlockModule = () => {
    const [userEmail, setUserEmail] = useState("");
    const [userPhase, setUserPhase] = useState("");
    const [userModule, setUserModule] = useState("");
    const [isLoading, setLoading] = useState(false);
    const [formInvalid, setFormInvalid] = useState(false);
    const [responseError, setResponseError] = useState(false);
    const [responseErrorString, setResponseErrorString] = useState("");
    const [responseSuccess, setResponseSuccess] = useState(false);
    const [infoAlert, setInfoAlert] = useState(true);
    const [pricingPlan, setPricingPlan] = useState(null);
    const [userPlan, setUserPlan] = useState(null);

    const dispatch = useDispatch();
    const authentication = useSelector((store) => store?.authentication);

    useEffect(() => {
        dispatch(getPricingPlans("DM", success => {
            setPricingPlan(success.pricing_plans.filter(item => item.name !== "Free Plan"))
        }))
    }, [])

    const handleEmailChange = (e) => {
        setUserEmail(e.target.value);
    }

    const handlePhaseSelected = (e) => {
        setUserPhase(e.target.value);
    }

    const handleModuleSelected = (e) => {
        setUserModule(e.target.value);
    }

    const handleUnlockModuleClick = (e) => {
        setLoading(true)
        if (userEmail === "" || userPhase === "NA" || userModule === "NA") {
            setFormInvalid(true);
            setLoading(false);
            setResponseError(false);
            setResponseSuccess(false);
            return;
        } else {
            dispatch(unlockModule({
                "user_email": userEmail, "phaseName": userPhase, "moduleNum": userModule
            }, res => {
                setResponseSuccess(true);
                setResponseError(false);
            }, err => {
                setResponseError(true);
                setResponseErrorString(err.errors)
            }))
            setFormInvalid(false);
            setLoading(false);
        }
    }

    const handlePricingLinksUnlock = (email) => {
        if (email !== "") {
            dispatch(getUserPricingUnlock(email, (successObj) => {
                setUserPlan(successObj.userPlans)
            }))
        }
    }

    const handleEditUserPricing = (plan) => {
        if (userPlan.includes(plan)) {
            setUserPlan(userPlan.filter(userPlan => userPlan !== plan))
        } else {
            setUserPlan([...userPlan, plan])
        }
    }

    const handleUserPricingSubmit = () => {
        dispatch(setUserPricingUnlock({ userEmail, userPlan }, successObj => {
            setUserEmail("")
            setUserPlan(null)
        }))
    }

    const handlePricingCancel = ()=>{
        setUserEmail("")
        setUserPlan(null)
    }


    return (
        <div>
            <AccessKeyModal showModal={!authentication?.accessKeyEntered} />
            <Container>
                {formInvalid && <Alert variant="danger" onClose={() => setFormInvalid(false)} dismissible>
                    <Alert.Heading>Missing or Invalid Input Parameters</Alert.Heading>
                </Alert>}

                {responseError && <Alert variant="danger" onClose={() => setResponseError(false)} dismissible>
                    <Alert.Heading>{responseErrorString}</Alert.Heading>
                </Alert>}

                {responseSuccess && <Alert variant="success" onClose={() => setResponseSuccess(false)} dismissible>
                    <Alert.Heading>Successfully Unlocked!</Alert.Heading>
                </Alert>}

                {infoAlert && <Alert variant="secondary" onClose={() => setInfoAlert(false)} dismissible>
                    Info: Enter user email, phase and the module to be unlocked. Request the dev-team to undo this action.
                </Alert>}
                <Form>
                    <Form.Group>
                        <Row className="justify-content-md-center">
                            <Col md>
                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                    <Form.Label>Email address</Form.Label>
                                    <Form.Control type="email" onChange={handleEmailChange} placeholder="Enter email" />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Label>Phase</Form.Label>
                                <Form.Select onChange={handlePhaseSelected} aria-label="Discovery">
                                    <option value="NA">Select</option>
                                    <option value="Discovery">Discovery</option>
                                    <option value="Learning">Learning</option>
                                    <option value="Achieve">Achieve</option>
                                </Form.Select>
                            </Col>
                            <Col>
                                <Form.Label>Module</Form.Label>
                                <Form.Select onChange={handleModuleSelected} aria-label="Discovery">
                                    <option value="NA">Select</option>
                                    <option value="1">Discovery</option>
                                    <option value="1">Foundation</option>
                                    <option value="2">Content Marketing</option>
                                    <option value="3">Social Media</option>
                                    <option value="4">Search Engine Optimization</option>
                                    <option value="5">Search Engine Marketing</option>
                                    <option value="6">Email Marketing and Lead</option>
                                    <option value="7">Account Management</option>
                                    <option value="8">Web Analytics</option>
                                    <option value="9">Mobile Marketing</option>
                                    <option value="10">Branding</option>
                                    <option value="11">CRM</option>
                                    <option value="12">Remarketing and Growth</option>
                                    <option value="13">E-Commerce and Affiliate</option>
                                    <option value="14">Influencer Marketing</option>
                                    <option value="15">Freelancing</option>
                                    <option value="16">Media Planning</option>
                                    <option value="17">Display Advertising</option>
                                    <option value="18">Native Ads</option>
                                    <option value="3">Interviewing</option>
                                    <option value="5">Getting Started</option>
                                    <option value="6">Looking For Clients</option>
                                    <option value="7">Closing The Deal</option>
                                    <option value="8">Delivering For Long Term Success</option>
                                </Form.Select>
                            </Col>
                        </Row>
                        <Row className="align-items-center mt-3">
                            <Col>
                                <Button disabled={(responseError || formInvalid || responseSuccess || !authentication.accessKeyEntered)} variant="primary" size="lg" onClick={handleUnlockModuleClick}>
                                    Unlock Module
                                </Button>
                                {(responseError || formInvalid || responseSuccess) &&
                                    <h7 className="px-3" style={{ fontSize: "11px" }}>*Close alert to run again</h7>
                                }
                            </Col>
                        </Row>
                    </Form.Group>
                </Form>
                <hr />
                <Form>
                    <h4>Unlock Payment Links</h4>
                    <Form.Group>
                        <Row className="justify-content-md-center align-items-center">
                            <Col>
                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                    <Form.Label>Email address</Form.Label>
                                    <Form.Control type="email" onChange={handleEmailChange} placeholder="Enter email" value={userEmail} />
                                </Form.Group>
                            </Col>
                            <Col>
                                <Button variant='primary' size='lg' onClick={() => handlePricingLinksUnlock(userEmail)}>
                                    Get Status
                                </Button>
                            </Col>
                        </Row>
                    </Form.Group>
                    {userPlan &&
                        <Form.Group>
                            <Row>
                                {pricingPlan && pricingPlan.map((plan, index) => (
                                    <Col xs={5} className='mt-4 mx-auto' key={index}>
                                        <FormCheck
                                            id={index}
                                            type='switch'
                                            label={plan.name}
                                            checked={userPlan.includes(plan.name)}
                                            size={'lg'}
                                            style={{ fontSize: "1.5rem" }}
                                            onChange={() => handleEditUserPricing(plan.name)}
                                        />
                                    </Col>

                                ))}
                            </Row>
                            <Row className='justify-content-center align-items-center mt-4'>
                                <Col>
                                    <Button className='mx-3' variant='success' onClick={handleUserPricingSubmit}>Save Changes</Button>
                                    <Button className='mx-3' variant='danger' onClick={handlePricingCancel}>Cancel</Button>
                                </Col>
                            </Row>
                        </Form.Group>}
                </Form>
            </Container>
        </div>
    );
}

export default UserUnlockModule;