import { Container, Breadcrumb } from "react-bootstrap";
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import { useEffect, useState } from "react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "./transaction-details.scss";
import { useDispatch } from "react-redux";
import { getAllTransactions } from "../../redux/actions/user-course-extension";
import { toast } from "react-toastify";

export default function TransactionDetails() {
  const [transactionDetails, setTransactionDetails] = useState([]);
  const [gridApi, setGridApi] = useState(null);

  const onGridReady = (params) => {
    setGridApi(params.api);
  };

  const onSelectionChanged = () => {
    const selectedRows = gridApi.getSelectedRows();
  };

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      getAllTransactions(
        (resObj) => {
          setTransactionDetails(resObj);
        },
        (errObj) => {
          console.log(errObj);
          toast.error("unable to get data! Please contact Developer!");
        }
      )
    );
  }, []);

  return (
    <Container fluid>
      <Breadcrumb>
        <Breadcrumb.Item active>Transaction Details</Breadcrumb.Item>
      </Breadcrumb>

      <div className="ag-theme-alpine grid-div">
        <AgGridReact
          rowData={transactionDetails}
          defaultColDef={{
            flex: 1,
            minWidth: 150,
            resizable: true,
            floatingFilter: true,
          }}
          rowSelection={"single"}
          onGridReady={onGridReady}
          onSelectionChanged={onSelectionChanged}
          pagination
        >
          <AgGridColumn
            headerName="S No."
            valueGetter={(params) => params.node.rowIndex + 1}
          />
          <AgGridColumn field="_id" sortable filter />
          <AgGridColumn field="leadOwner" headerName="Lead Owner" sortable filter />
          <AgGridColumn field="leadName" headerName="Lead Name" sortable filter />
          <AgGridColumn field="email" sortable filter></AgGridColumn>
          <AgGridColumn field="currency" sortable filter></AgGridColumn>
          <AgGridColumn
            field="paid_amount"
            sortable
            filter
            aggFunc
          ></AgGridColumn>
          <AgGridColumn field="refunded_amount" sortable filter></AgGridColumn>
          <AgGridColumn field="days_extended" sortable filter></AgGridColumn>
          <AgGridColumn field="payment_method" sortable filter></AgGridColumn>
          <AgGridColumn
            field="razorpay_order_id"
            sortable
            filter
          ></AgGridColumn>
          <AgGridColumn field="course_key" sortable filter></AgGridColumn>
          <AgGridColumn field="scheme" headerName="Plan" sortable filter></AgGridColumn>
          <AgGridColumn field="customPlan" headerName="Custom Plan" sortable filter></AgGridColumn>
          <AgGridColumn field="type" sortable filter></AgGridColumn>
          <AgGridColumn field="emi_no" sortable filter></AgGridColumn>
          <AgGridColumn field="payment_date" sortable filter></AgGridColumn>
          <AgGridColumn field="certificate" sortable filter></AgGridColumn>
          <AgGridColumn field="cv" sortable filter></AgGridColumn>
          <AgGridColumn field="note" sortable filter></AgGridColumn>
          <AgGridColumn field="created_at" sortable filter></AgGridColumn>
          <AgGridColumn field="updated_at" sortable filter></AgGridColumn>
        </AgGridReact>
      </div>
    </Container>
  );
}
