import { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Breadcrumb,
  Container,
  Form,
  Col,
  Button,
  Row,
  Modal,
  Toast,
  Spinner,
} from "react-bootstrap";
import {
  verifyUserCourse,
  getPaymentRecord,
  deleteUser,
  disableUserAccess,
  getUserPathwayDetails,
} from "../../redux/actions/user-course-extension";
import * as dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import UserPlanDetails from "./components/UserPlanDetails";
import UserExtensionForm from "./components/UserExtensionForm";
import PaymentHistory from "./components/PaymentHistory";

dayjs.extend(relativeTime);

function UserCourseExtension() {
  const [email, setEmail] = useState("");
  const [course, setCourse] = useState("DM");
  const [userDetails, setUserDetails] = useState();
  const [showUserExtensionForm, setUserExtensionFormFlag] = useState(false);
  const [paymentHistory, setPaymentHistory] = useState([]);
  const [showPaymentHistory, setPaymentHistoryFlag] = useState(false);
  const [confirmDeleteUser, setConfirmDeleteUser] = useState(false);
  const [note, setNote] = useState("");
  const [spinner, setSpinner] = useState(false);
  const [lastBooking, setLastBooking] = useState(null);
  const [toast, setToast] = useState({
    message: "Careerninja Admin",
    show: false,
    type: "secondary",
  });
  const [confirmDisableUser, setConfirmDisableUser] = useState(false);
  const [disableUserNote, setDisableUserNote] = useState("");

  const dispatch = useDispatch();

  const admin_user_email = useSelector((store) => store.authentication.email);

  useEffect(() => {
    if (
      userDetails &&
      userDetails.user_found &&
      userDetails?.subscription?.active !== "Free"
    ) {
      const data = {
        cond: JSON.stringify({
          email: email,
          type: "BOOK",
          scheme: userDetails?.subscription?.active
            .replace(/ /g, "_")
            .toUpperCase(),
        }),
        sort: "updated_at",
      };
      dispatch(getPaymentRecord(data, (resobj) => setLastBooking(resobj[0])));
    }
  }, [userDetails]);

  const getUserDetails = useCallback(() => {
    const data = { email: email, course_key: course };
    dispatch(
      verifyUserCourse(data, (resobj) => {
        if (resobj.end_date) {
          const lastday = dayjs(resobj.end_date);
          const difference = lastday.diff(new Date(), "minutes");
          const today = dayjs(new Date());

          resobj["expiry_time"] =
            difference < 0 ? lastday.from(today) : today.to(lastday);
          resobj["is_inactive"] = difference < 0 ? true : false;
        }
        setUserDetails(prev => ({ ...prev, ...resobj }));
      })
    );
    dispatch(getUserPathwayDetails(data, (resObj) => {
      setUserDetails(prev => ({ ...prev, onboarding: resObj.parameters }))
    }))
  }, [email, course]);

  const toggleUserExtensionForm = () =>
    setUserExtensionFormFlag((prev) => !prev);

  const togglePaymentHistory = () => {
    if (showPaymentHistory === false && paymentHistory.length === 0) {
      const data = {
        cond: JSON.stringify({ email: email, course_key: course }),
        sort: "updated_at",
      };
      dispatch(
        getPaymentRecord(data, (resobj) => {
          setPaymentHistory(resobj);
          setPaymentHistoryFlag(true);
        })
      );
    } else {
      setPaymentHistoryFlag((prev) => !prev);
    }
  };

  const clearData = () => {
    setEmail("");
    setUserDetails(undefined);
    setPaymentHistory([]);
    setPaymentHistoryFlag(false);
    setUserExtensionFormFlag(false);
    setNote(null);
    setConfirmDeleteUser(false);
    setSpinner(false);
    setDisableUserNote(null);
    setConfirmDisableUser(false);
  };

  const resetUser = () => {
    setSpinner(true);
    if (note.length > 5) {
      const body = {
        user_email: userDetails.email,
        email: admin_user_email,
        note: note,
      };
      dispatch(
        deleteUser(
          body,
          (resobj) => {
            setToast({
              show: true,
              message: "User Deleted Successfully",
              type: "success",
            });
            setSpinner(false);
            clearData();
          },
          (errobj) => {
            setToast({
              show: true,
              message:
                "Error deleting the user. Please report it to developer!",
              type: "danger",
            });
            setSpinner(false);
            console.log(errobj);
          }
        )
      );
    } else {
      setToast({
        show: true,
        message: "Reason note cannot be less than 6 letters",
        type: "danger",
      });
      setSpinner(false);
    }
  };

  const disableUser = () => {
    setSpinner(true);
    if (disableUserNote.length > 5) {
      const body = {
        user_email: userDetails.email,
        email: admin_user_email,
        note: disableUserNote,
      };
      dispatch(
        disableUserAccess(
          body,
          (resObj) => {
            setToast({
              show: true,
              message: "User locked successfully",
              type: "success",
            });
            setSpinner(false);
            clearData();
          },
          (errObj) => {
            setToast({
              show: true,
              message: "Error Locking user. Please report it to developer!",
              type: "danger",
            });
            setSpinner(false);
            console.log(errObj);
          }
        )
      );
    } else {
      setToast({
        show: true,
        message: "Reason note cannot be less than 6 letters",
        type: "danger",
      });
      setSpinner(false);
    }
  };

  return (
    <Container fluid>
      <Toast
        onClose={() => setToast({ ...toast, show: false })}
        show={toast.show}
        style={{
          position: "fixed",
          top: "60px",
          right: "30px",
          zIndex: "999999999",
        }}
        delay={3000}
        autohide
        bg={toast.type}
      >
        <Toast.Body className="text-white">{toast.message}</Toast.Body>
      </Toast>

      <div>
        <Breadcrumb>
          <Breadcrumb.Item active>User Course Extension</Breadcrumb.Item>
        </Breadcrumb>

        <Row className="align-items-end">
          <Form.Group as={Col} xl="3">
            <Form.Label>Email address</Form.Label>
            <Form.Control
              type="email"
              placeholder="Enter email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </Form.Group>

          <Form.Group as={Col} xl="3">
            <Form.Label>Select Course</Form.Label>
            <Form.Select>
              <option value="DM">Digital Marketing</option>
            </Form.Select>
          </Form.Group>

          <Form.Group as={Col} xl="3">
            <Button variant="primary" onClick={getUserDetails}>
              Check Status
            </Button>
            <Button
              variant="primary"
              style={{ marginLeft: "20px" }}
              onClick={clearData}
            >
              Clear
            </Button>
          </Form.Group>
        </Row>
      </div>

      <hr />

      <UserPlanDetails userDetails={userDetails} lastBooking={lastBooking} />

      <br />

      <Container>
        {userDetails && userDetails.user_found && (
          <div className="d-flex justify-content-end w-100">
            <Button
              variant="danger"
              style={{ marginRight: "14px", color: "white", background: "maroon" }}
              onClick={() => setConfirmDisableUser(true)}
            >
              Disable Access
            </Button>
            {/* USER ACTION BUTTONS */}
            {!showUserExtensionForm && (
              <Button
                variant="danger"
                style={{ marginRight: "14px" }}
                onClick={() => setConfirmDeleteUser(true)}
              >
                Delete User
              </Button>
            )}
            {!showUserExtensionForm && (
              <Button variant="primary" onClick={toggleUserExtensionForm}>
                Record Transaction
              </Button>
            )}
            <Button
              variant="primary"
              style={{ marginLeft: "14px" }}
              onClick={togglePaymentHistory}
            >
              {showPaymentHistory ? "Hide" : "Show"} Payment History
            </Button>

            {/* DELTE USER CONFIRMATION MODAL */}
            <Modal show={confirmDeleteUser} centered size="xl">
              <Modal.Header>
                Are you sure you want to delete this user?
              </Modal.Header>

              <Modal.Body>
                <div>
                  User email: <b>{userDetails.email}</b>
                </div>
                <p>Please enter a reason for deleting the user</p>

                <Form.Control
                  as="textarea"
                  name="note"
                  value={note}
                  onChange={(e) => setNote(e.target.value)}
                />
                <br />

                <div className="d-flex justify-content-end">
                  <Button
                    variant="danger"
                    className="d-flex"
                    style={{ marginRight: "14px" }}
                    onClick={resetUser}
                  >
                    {spinner && (
                      <Spinner
                        animation="border"
                        role="status"
                        style={{
                          marginRight: "10px",
                          height: "20px",
                          width: "20px",
                          marginTop: "auto",
                          marginBottom: "auto",
                        }}
                      >
                        <span className="visually-hidden">Loading...</span>
                      </Spinner>
                    )}
                    Confirm
                  </Button>
                  <Button
                    variant="secondary"
                    onClick={() => setConfirmDeleteUser(false)}
                  >
                    Cancel
                  </Button>
                </div>
              </Modal.Body>
            </Modal>
            {/* DISABLE/LOCK USER CONFIRMATION MODAL */}
            <Modal show={confirmDisableUser} centered size="x1">
              <Modal.Header>
                Are you sure you want to Disable This User?
              </Modal.Header>

              <Modal.Body>
                <div>
                  User email: <b>{userDetails.email}</b>
                </div>
                <p>Please enter a reason for disabling the user</p>

                <Form.Control
                  as="textarea"
                  name="disableUserNote"
                  value={disableUserNote}
                  onChange={(e) => setDisableUserNote(e.target.value)}
                />
                <br />

                <div className="d-flex justify-content-end">
                  <Button
                    variant="danger"
                    className="d-flex"
                    style={{ marginRight: "14px" }}
                    onClick={disableUser}
                  >
                    {spinner && (
                      <Spinner
                        animation="border"
                        role="status"
                        style={{
                          marginRight: "10px",
                          height: "20px",
                          width: "20px",
                          marginTop: "auto",
                          marginBottom: "auto",
                        }}
                      >
                        <span className="visually-hidden">Loading...</span>
                      </Spinner>
                    )}
                    Confirm
                  </Button>
                  <Button
                    variant="secondary"
                    onClick={() => setConfirmDisableUser(false)}
                  >
                    Cancel
                  </Button>
                </div>
              </Modal.Body>
            </Modal>
          </div>
        )}
      </Container>

      {showPaymentHistory && (
        <>
          <PaymentHistory paymentDetails={paymentHistory} userDetails={userDetails} />
          {showUserExtensionForm && (
            <>
              <br />
              <hr />
            </>
          )}
        </>
      )}

      {showUserExtensionForm && (
        <UserExtensionForm
          userDetails={userDetails}
          courseKey={course}
          closeUserExtensionForm={toggleUserExtensionForm}
          updateUserDetails={getUserDetails}
        />
      )}
    </Container>
  );
}

export default UserCourseExtension;
