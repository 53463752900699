import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import './login.scss'
import { login } from '../../redux/actions/authentication';
import Routes from '../../config/routes';
import Icon from '../../utils/icon';
import { Redirect } from 'react-router-dom';

const Login = (props) => {

    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [error, setError] = useState(null)
    const token = useSelector(store => store.authentication.token)
    const [dashboardRedirect, setDashboardRedirect] = useState(false)
    const dispatch = useDispatch()

    useEffect(() => {
        if (token)
            setDashboardRedirect(true)
    }, [])

    const loginHandler = () => {
        const data = { email: email, password: password }

        dispatch(login(data, (resobj) => {
            //success callback
            if (resobj.token !== null && (resobj.role === 'ADMIN' || resobj.role === 'MODERATOR'))
                props.history.push(Routes.Dashboard)
            else
                props.history.push(Routes.Login)

        }, (err) => {
            //error callback
            setError(err.errors)
        }))
    }

    const inputHandler = (e) => {
        e && e.stopPropagation()
        const { value, name } = e.target

        if (name === "email") setEmail(value);

        if (name === "password") setPassword(value);

    }

    return (
        <div className='login-page-container'>

            {/* DASHBOARD REDIRECT */}
            {dashboardRedirect && <Redirect to={Routes.Dashboard} />}

            <div className='box'>
                <Icon icon='ICON_CAREER_NINJA_WHITE' />
                <img className="careerninja-white-text" src="/img/careerninja-white-text.png" alt="" />
            </div>

            <div className='m-0 p-0 d-flex align-items-center justify-content-center login-form-wrapper'>

                <div className='signup-via-email-form'>
                    <p className='signup-via-email-head'>Login Via Email</p>
                    <div className='email-input'>
                        <div className='custom-form-label'>Email Address</div>
                        <input
                            type='text'
                            className='input'
                            placeholder='Your primary address'
                            name="email"
                            value={email}
                            onChange={inputHandler}
                        />
                        {/* <Link href='' className='forgot-link'>Forgot email address?</Link> */}
                    </div>
                    <div className='password-input'>
                        <div className='custom-form-label'>Password</div>
                        <input
                            type='password'
                            className='input'
                            placeholder='Must be 8 digits or more'
                            name="password"
                            value={password}
                            onChange={inputHandler}
                        />
                        {/* <Link href='' className='forgot-link'>Forgot password?</Link> */}

                    </div>
                    <br />

                    {/* <div className='checkbox-input'>
                <input
                  type='checkbox'
                  name='terms'
                  value='terms checked'
                  className='checkbox'
                />
                <span>Remember me next time</span>
              </div> */}
                    {error && <p className='invalid-email-error error-message-sm'>{error}</p>}

                    <button className='btn-fill mt-3' onClick={loginHandler} value='Login'>Log In</button>
                </div>

            </div>
        </div>
    )
}

export default Login
